import { abbreviateNumber } from '@glow/common'
import { BannerIdType, WhatsThisModalIdType } from '@glow/entity-types'
import { saveAs } from 'file-saver'
import i18next from 'i18next'
import { List, Map, Set, fromJS } from 'immutable'
import toString from 'lodash/toString'
import { DateTime, Duration } from 'luxon'
import React, { ErrorInfo } from 'react'
import { CustomsAgentFormValues } from '../../components/airexpress/CustomsAgentUserForms'
import { DeliveryTime } from '../../components/newShipmentModal/shared/Shipment'
import { UnitFormProps } from '../../components/unit/UnitForm'
import { CustomerIdType } from '../../domain/customer'
import { UnresolvedAddressIdType } from '../../domain/unresolvedAddress'
import { ConsumedResponse, QueryData, api, asyncOptimusCreateOrders, checkoutApi } from '../../http/httpHelper'
import { CustomerEventEmailFormProps } from '../../pages/admin/CustomerEventEmailModal'
import { UserRolesPayload } from '../../pages/admin/user-roles/utils'
import { CreateBannerPayload, UpdateBannerPayload } from '../../pages/cms/bannerUtils'
import { CreateWhatsThisModalPayload, UpdateWhatsThisModalPayload } from '../../pages/cms/modalUtils'
import { UnitSuggestionType } from '../../pages/instant/SuggestedUnits'
import { customerNumberAndSubcustomer } from '../../pages/instant/bookingOrderFunctions'
import { CorrectedArticlePrice, IPricedArticle } from '../../pages/instant/bookingOrderTypes'
import { CreateUpdateCourierFormProps } from '../../pages/planner/courier/courierPageProps'
import { BillingOrder } from '../../pages/planner/page-billing-order/billingOrder'
import { DateParams } from '../../pages/planner/pickup-orders/PickupOrders'
import { SearchParams as ShipmentSearchParams } from '../../pages/shipments/search-page/ShipmentSearchTypes'
import { httpDone } from '../../reducers/httpStatus'
import { getRawErrorTexts } from '../../selectors/httpStatusSelectors'
import { IError } from '../../selectors/optimizeJobResultsSelector'
import {
  ConsignmentIdType,
  CourierUserId,
  CustomerGroup,
  CustomerGroupIdType,
  DepartmentFormValues,
  DepartmentGroup,
  DepartmentGroupIdType,
  DepartmentGroupProps,
  DepartmentIdType,
  ISODateString,
  OrderIdType,
  PlannerProps,
  RoleId,
  SessionUser,
  Slot,
  SlotIdType,
  UnitIdType,
  VehicleIdType
} from '../../types/coreEntitiesTypes'
import { ImmutableMap } from '../../types/immutableTypes'
import { AppSettingsType } from '../../utils/AppSettings'
import { isNotEmpty } from '../../utils/collectionUtils'
import { formatAsLocalDate } from '../../utils/dateTime'
import { userLanguage } from '../../utils/language'
import { ROLE_READ_ONLY_PLANNER } from '../../utils/roles'
import { ServiceVasText } from '../../utils/serviceUtils'
import {
  ACCEPT_ORDERS,
  ADD_CONSIGNMENT_TO_SLOT_AND_REOPTIMIZE,
  ADD_ROLES_TO_USER,
  ADD_SCANNED_EVENTS,
  ADD_SORTED_EVENTS,
  ADD_TEXT,
  ADD_UPDATE_SERVICE_MAPPING,
  ASSIGN_COURIER_TO_SLOT,
  ASSIGN_UNIT_TO_SLOT,
  ASSIGN_VEHICLE_TO_SLOT,
  AUTOSUGGEST_CUSTOMERS,
  AVAILABLE_DELIVERY_TIMES_ADD,
  ActionType,
  BATCH_CONSIGNMENT_UPDATE,
  CANCEL_RUNNING_OPTIMIZING,
  CLAIM_EMAIL,
  CLEAR_AGGREGATES,
  CLEAR_ALL_HTTP_STATUS,
  CLEAR_APOLLO_AUDIT_LOG,
  CLEAR_ENTITIES,
  CLEAR_ENTITY_COMMAND_RESULT,
  CLEAR_OPTIMIZING_DATA,
  CLEAR_ORDER_IDS_ON_MOVED_ORDERS,
  CLEAR_SEARCH_ADDRESSES,
  CLEAR_SEARCH_CUSTOMERS,
  COLLECT_CONSIGNMENTS_MANUAL_OVERRIDE,
  COMPLETE_SLOT_PLANNING,
  COPY_DEVIATED_ORDER,
  CREATE_BANNER,
  CREATE_BILLING_ORDER,
  CREATE_COURIER_USER,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_GROUP,
  CREATE_DEPARTMENT,
  CREATE_DEPARTMENT_GROUP,
  CREATE_HOLIDAY,
  CREATE_LABEL,
  CREATE_LABELS_FOR_DEPARTMENT,
  CREATE_MULTILEG_RETURN,
  CREATE_MULTILEG_RETURNS,
  CREATE_MULTIPLE_LABEL,
  CREATE_NUMBER_SERIES,
  CREATE_ORDER_DEVIATION,
  CREATE_ORDER_OPTIMUS,
  CREATE_OR_UPDATE_TEMPLATE,
  CREATE_PLANNER,
  CREATE_PRE_ADVICE_POLICY,
  CREATE_RETURN_LABELS,
  CREATE_ROLE,
  CREATE_ROUTE,
  CREATE_SLOTS_FROM_TEMPLATE,
  CREATE_UNIT,
  CREATE_UPDATE_CUSTOMER_SMS_TEXT,
  CREATE_VEHICLE,
  CREATE_WAYBILLS_REQUEST,
  CREATE_WHATS_THIS_MODAL,
  DELETE_BANNER,
  DELETE_BREAK_FROM_ROUTE,
  DELETE_CUSTOMER_GROUP,
  DELETE_DEPARTMENT_GROUP,
  DELETE_HOLIDAY,
  DELETE_NUMBER_SERIES,
  DELETE_ORDERS,
  DELETE_ORDER_DEVIATION,
  DELETE_PRE_ADVICE_POLICY,
  DELETE_ROLE,
  DELETE_SERVICE_MAPPING,
  DELETE_SLOT,
  DELETE_TEMPLATE,
  DELETE_TEXT,
  DELETE_TIME_MATRIX,
  DELETE_USER,
  DELETE_WHATS_THIS_MODAL,
  DELIVER_CONSIGNMENT_MANUAL_OVERRIDE,
  DEVIATE_CONSIGNMENT_MANUAL_OVERRIDE,
  DISABLE_JOB,
  DRAWER_CLOSE,
  DRAWER_OPEN,
  ENABLE_JOB,
  EVENTS_WITH_SENT_LM_STATUS,
  FORCE_UNASSIGN_CONSIGNMENTS,
  GET_ALL_BANNERS,
  GET_ALL_CUSTOMERS,
  GET_ALL_DEPARTMENTS,
  GET_ALL_WHATS_THIS_MODALS,
  GET_APOLLO_AUDIT_LOG,
  GET_APP_SETTING,
  GET_ARTICLES,
  GET_BANNER_BY_ID,
  GET_CONSIGNMENTS,
  GET_COURIER_LOCATIONS,
  GET_CUSTOMERS_WITH_TIME_MATRIX,
  GET_CUSTOMER_EVENT_EMAIL,
  GET_CUSTOMER_EXTERNAL_CUSTOMER_NUMBERS,
  GET_CUSTOMER_GROUPS,
  GET_CUSTOMER_GROUP_MEMBERS,
  GET_CUSTOMER_RETURN_ADDRESS,
  GET_CUSTOMER_SMS_TEXT,
  GET_DELIVERY_NOTES,
  GET_DELIVERY_WINDOWS,
  GET_DEPARTMENTS,
  GET_DEPARTMENTS_IN_SAME_COUNTRY,
  GET_DEPARTMENT_GROUPS,
  GET_DEPARTMENT_TIME_WINDOWS,
  GET_HOLIDAYS_DATA,
  GET_IMPORT_RESULTS,
  GET_IMPORT_STATUS,
  GET_INVOICING_DATA,
  GET_JOBS_STATUS,
  GET_LATEST_OPTIMIZING_ID_BY_SLOT,
  GET_LOGIN_PAGE_BANNER,
  GET_MULTIPLE_LABEL,
  GET_MULTISTOP_ORDER_PRICE_DATA,
  GET_NUMBER_SERIES,
  GET_OPTIMIZE_REQUEST,
  GET_OPTIMIZING_DATA,
  GET_OPTIMIZING_ERROR_DATA,
  GET_OPTIMIZING_REQUEST_DATA,
  GET_OPTIMIZING_RESPONSE_DATA,
  GET_ORDERS_BY_ORDER_IDS,
  GET_ORDERS_BY_RECURRING_ORDER_ID,
  GET_ORDER_BY_SHIPMENT_ID,
  GET_ORDER_DEVIATIONS,
  GET_ORDER_PRICE_DATA,
  GET_ORDER_RETURN_DETAILS,
  GET_PRE_ADVICE,
  GET_PRE_ADVICE_POLICIES,
  GET_PRE_ADVICE_SERVICE_POLICIES,
  GET_PUBLISHED_BANNERS,
  GET_PUBLISHED_WHATS_THIS_MODALS,
  GET_ROUTE_POINTS,
  GET_SERVICES_AND_VASES_TEXTS,
  GET_SERVICE_MAPPING_DATA,
  GET_SLOT,
  GET_SLOTS,
  GET_SLOTS_BY_UNIT_ID,
  GET_SUBCUSTOMERS,
  GET_SUGGESTED_UNITS_FOR_LOCATION,
  GET_SUGGESTED_UNITS_ON_DELIVERY,
  GET_SUGGESTED_UNITS_ON_PICKUP,
  GET_TEMPLATES,
  GET_TEXTS,
  GET_TEXT_BY_ID,
  GET_UNCONFIRMED_ORDERS,
  GET_UNCONFIRMED_ORDERS_COUNT,
  GET_UNITS,
  GET_UNRESOLVED_ADDRESSES,
  GET_UNRESOLVED_ADDRESS_COUNT,
  GET_VEHICLES,
  GET_WAYBILLS,
  GET_WHATS_THIS_MODAL_BY_ID,
  HTTP_FAILURE_ERRORS_READ,
  LOCK_OR_UNLOCK_PRE_ADVICE,
  MANUAL_PRE_ADVICE,
  MARK_NOT_ARRIVED_AT_DISTRIBUTING_TERMINAL,
  MODAL_CLOSE,
  MODAL_CLOSE_KEEP_PAGE_STATE,
  MODAL_OPEN,
  MOVE_ORDERS,
  MOVE_ORDERS_NEW,
  PAGE_STATE_ADD,
  PRE_ADVICE_MANUAL_ATTEMPT,
  QUICK_EDIT_SHIPMENT,
  REJECT_ORDERS,
  REMOVE_AREA_FROM_SLOT,
  REMOVE_BOOKING_TIMES,
  REMOVE_ROLES_FROM_USER,
  REQUEST_PWD_RESET,
  RESET_ORDER_PRICE_INFO,
  RESOLVE_ADDRESS,
  RETURN_CONSIGNMENTS_MANUAL_OVERRIDE,
  REVERSED_OPTIMIZE_SLOT,
  ROLES,
  SAVE_CUSTOMER_EVENT_EMAIL,
  SEARCH_CONSIGNMENTS,
  SEARCH_CUSTOMERS,
  SEARCH_DELETED_ORDERS,
  SEARCH_H2_ORDER,
  SEARCH_ORDERS,
  SEND_MANUAL_DELAY_SMS,
  SEND_MANUAL_DELAY_SMS_NEW,
  SEND_PRE_PICKUP_SMS,
  SEND_PRE_PICKUP_SMS_NEW,
  SET_CONSIGNMENT_SLOT,
  SET_COURIER_LOCATION,
  SET_CUSTOMER_SERVICE_LEVEL,
  SET_DEPARTMENT_DEFAULT_TEMPLATES,
  SET_REACT_RENDER_ERROR,
  TOGGLE_SHOW_CUSTOMER_PRICE,
  UNASSIGN_BULK_CONSIGNMENTS_FROM_SLOTS,
  UNASSIGN_CONSIGNMENTS,
  UNASSIGN_CONSIGNMENT_FROM_SLOT,
  UNASSIGN_COURIER_FROM_SLOT,
  UNASSIGN_UNIT_FROM_SLOT,
  UNASSIGN_VEHICLE_FROM_DEPARTMENT,
  UNASSIGN_VEHICLE_FROM_SLOT,
  UNRESOLVE_DELIVERY_ADDRESS,
  UNRESOLVE_PICKUP_ADDRESS,
  UPDATE_APP_SETTING,
  UPDATE_AREA_IN_SLOT,
  UPDATE_BANNER,
  UPDATE_BRANDED_TRACKING_ACCESS,
  UPDATE_CONSIGNMENTS_PICKUP_LOCATION,
  UPDATE_COURIER_USER,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_BILLING_NUMBER,
  UPDATE_CUSTOMER_BOOKING_PAGE_ACCESS,
  UPDATE_CUSTOMER_GROUP,
  UPDATE_CUSTOMER_HD_INVOICING_FROM_DATE,
  UPDATE_CUSTOMER_INFORMATION,
  UPDATE_CUSTOMER_NPS_FROM_DATE,
  UPDATE_CUSTOMER_RETURN_ADDRESS,
  UPDATE_CUSTOMER_RETURN_LAST_LEG,
  UPDATE_CUSTOMER_SCAN_TO_CREATE_PACKAGES,
  UPDATE_CUSTOMER_SENDER_NAME,
  UPDATE_DELIVERY_TIME,
  UPDATE_DEPARTMENT,
  UPDATE_DEPARTMENT_GROUP,
  UPDATE_MULTISTOP_PRICE_CORRECTION,
  UPDATE_ORDER_DEVIATION,
  UPDATE_ORDER_SERVICE_LEVEL,
  UPDATE_ORDER_VEHICLE_TYPE,
  UPDATE_PICKUP_AND_DELIVERY_TIMES,
  UPDATE_PLANNER,
  UPDATE_ROLE,
  UPDATE_ROUTE,
  UPDATE_TEXT,
  UPDATE_VEHICLE_INFORMATION,
  UPDATE_WHATS_THIS_MODAL,
  UPLOAD_EXCEL_ADDRESSES,
  UPLOAD_EXCEL_ORDERS_V2,
  UPSERT_DEPARTMENT_TIME_WINDOW,
  VALIDATE_BANNER_URL
} from '../actionTypes'
import { AnyData, ThunkDispatch, ThunkResult, fetchEvenIfPresent, fetchIfNotPresent } from './baseHelpers'
import { addNotification } from './helpersNotifications'

export function departmentIdsToList(departmentIds: number | List<number> | number[]): List<number> {
  if (typeof departmentIds == 'number') {
    return List([departmentIds])
  } else if (List.isList(departmentIds)) {
    return departmentIds
  } else if (departmentIds.length) {
    return List(departmentIds)
  } else {
    return List()
  }
}

export const setPageStateValue = (key: string, value: any) => (dispatch: ThunkDispatch) =>
  dispatch({
    type: PAGE_STATE_ADD,
    key: key,
    value: value
  })

export const setAvailableDeliveryTimes = (key: string, value: any) => (dispatch: ThunkDispatch) =>
  dispatch({
    type: AVAILABLE_DELIVERY_TIMES_ADD,
    key: key,
    value: value
  })

export const queryLocal = (query: string, key?: string) => (dispatch: ThunkDispatch) =>
  dispatch({
    type: PAGE_STATE_ADD,
    key: key ? key : 'query',
    value: query
  })

export const getDepartments = () => fetchIfNotPresent(GET_DEPARTMENTS, { query: 'departments', params: {} })

export const getDepartmentTimeWindows = (departmentId: number) =>
  fetchFromCheckoutIfNotPresent(GET_DEPARTMENT_TIME_WINDOWS, {
    query: 'departmentTimeWindows',
    params: { departmentId }
  })

export const fetchFromCheckoutIfNotPresent: (
  actionType: ActionType,
  queryBody: QueryData
) => ThunkResult<Promise<ConsumedResponse>> =
  (actionType: ActionType, queryBody: QueryData) => (dispatch, getState) => {
    if (httpDone(getState, actionType, queryBody)) {
      return Promise.resolve(new Response(null, { status: 200 })) as Promise<ConsumedResponse>
    } else {
      return dispatch(checkoutApi.asyncQuery(actionType, queryBody))
    }
  }

export const getAllDepartmentsWithAdminLevelAccess = () =>
  fetchIfNotPresent(GET_ALL_DEPARTMENTS, { query: 'allDepartmentsWithAdminLevelAccess', params: {} })

export const getJobsStatus = () => fetchIfNotPresent(GET_JOBS_STATUS, { query: 'jobsStatus', params: {} })

export const createHoliday = (country: string, type: string, date: string) => (dispatch: ThunkDispatch) => {
  return dispatch(
    api.asyncCommand(CREATE_HOLIDAY, {
      type: 'CREATE_HOLIDAY',
      payload: {
        country: country,
        type: type,
        date: date
      }
    })
  )
}

export const getHolidays = () => fetchIfNotPresent(GET_HOLIDAYS_DATA, { query: 'holiday', params: {} })

export const getArticles = (orderId: OrderIdType) =>
  fetchIfNotPresent(GET_ARTICLES, {
    query: 'articles',
    params: { orderId }
  })

export const deleteHoliday = (id: number) => (dispatch: ThunkDispatch) => {
  return dispatch(api.asyncCommand(DELETE_HOLIDAY, { type: 'DELETE_HOLIDAY', payload: { id: id } }))
}

export const getNumberSeries = () => fetchIfNotPresent(GET_NUMBER_SERIES, { query: 'numberSeries', params: {} })

export const getOptimizeRequestUncached = (departmentId: number) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncQuery(GET_OPTIMIZE_REQUEST, {
      query: 'optimizeRequest',
      params: {
        department: departmentId
      }
    })
  )

export const getOptimizeRequest = (departmentId: number) =>
  fetchIfNotPresent(GET_OPTIMIZE_REQUEST, {
    query: 'optimizeRequest',
    params: {
      department: departmentId
    }
  })

export const getServiceMapping = () => (dispatch: ThunkDispatch) => {
  return dispatch(api.asyncQuery(GET_SERVICE_MAPPING_DATA, { query: 'serviceMappingData', params: {} }))
}

export const getServiceMappingById = (id: number) => (dispatch: ThunkDispatch) =>
  dispatch(api.asyncQuery(GET_SERVICE_MAPPING_DATA, { query: 'getServiceMappingDataById', params: { id } }))

export const addOrEditServiceMatrix = (payload: object) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(ADD_UPDATE_SERVICE_MAPPING, {
      type: ADD_UPDATE_SERVICE_MAPPING.name,
      payload
    })
  )

export const deleteServiceMatrix =
  (serviceCode: string, serviceName: string, alystraId: string | undefined) => (dispatch: ThunkDispatch) =>
    dispatch(
      api.asyncCommand(DELETE_SERVICE_MAPPING, {
        type: DELETE_SERVICE_MAPPING.name,
        payload: { serviceCode, serviceName, alystraId }
      })
    )

export const getRoutePoints = (
  customerId?: number,
  departmentIds?: List<number>,
  fromDate?: string,
  toDate?: string
) => {
  const params: any = {
    fromDate: fromDate || DateTime.local().toISODate(),
    toDate: toDate || DateTime.local().plus({ days: 1 }).toISODate()
  }
  customerId && (params.customerId = customerId)
  departmentIds && (params.departmentIds = departmentIds)

  return fetchIfNotPresent(GET_ROUTE_POINTS, { query: 'routePoints', params: params })
}

export const getVehicles = (departmentIds: number | List<number> | number[]) => {
  const params: any = {
    departmentIds: departmentIdsToList(departmentIds)
  }

  return fetchIfNotPresent(GET_VEHICLES, {
    query: 'vehicles',
    params: params
  })
}

export const unassignVehicleFromDepartment =
  (departmentId: DepartmentIdType, vehicleId: VehicleIdType) => (dispatch: ThunkDispatch) => {
    return dispatch(
      api.asyncDeleteCommand(UNASSIGN_VEHICLE_FROM_DEPARTMENT, {
        type: 'UNASSIGN_VEHICLE_FROM_DEPARTMENT',
        payload: { departmentId, vehicleId }
      })
    )
  }

export const createVehicle = (department: DepartmentIdType, payload: any) => (dispatch: ThunkDispatch) => {
  return dispatch(
    api.asyncDeleteCommand(CREATE_VEHICLE, {
      type: 'CREATE_VEHICLE',
      payload: { department, ...payload }
    })
  )
}
export const updateVehicle =
  (department: DepartmentIdType, vehicle: VehicleIdType, payload: any) => (dispatch: ThunkDispatch) => {
    return dispatch(
      api.asyncDeleteCommand(UPDATE_VEHICLE_INFORMATION, {
        type: 'UPDATE_VEHICLE_INFORMATION',
        payload: { department, vehicle, ...payload }
      })
    )
  }

export const getSlots = (
  departmentIds: number | List<number> | number[],
  fromDate: string,
  toDate: string,
  shallowFetch: boolean = false,
  excludeRoutePoints: boolean = false,
  includeProgress: boolean = false,
  slotIds?: List<SlotIdType>
) => {
  const params: any = {
    departmentIds: departmentIdsToList(departmentIds),
    fromDate: fromDate || DateTime.local().toISODate(),
    toDate: toDate || DateTime.local().plus({ days: 1 }).toISODate(),
    shallowFetch,
    excludeRoutePoints,
    includeProgress
  }

  if (slotIds) {
    params.slotIds = slotIds
  }

  return fetchIfNotPresent(GET_SLOTS, {
    query: 'slots',
    params: params
  })
}

export const getSlotsByUnitId = (unitId: UnitIdType, fromDate: DateTime) =>
  fetchIfNotPresent(GET_SLOTS_BY_UNIT_ID, {
    query: 'slots',
    params: {
      shallowFetch: true,
      excludeRoutePoints: true,
      fromDate: fromDate.toISODate(),
      toDate: fromDate.plus({ days: 1 }).toISODate(),
      unit: unitId
    }
  })

const searchCustomersFactory =
  (query: string) => (departmentIds: List<DepartmentIdType>, searchPhrase: string) => (dispatch: ThunkDispatch) => {
    return dispatch(
      api.asyncQuery(SEARCH_CUSTOMERS, {
        query: query,
        params: {
          departmentIds: departmentIdsToList(departmentIds),
          searchPhrase: searchPhrase
        }
      })
    )
  }

export const searchCustomersWithoutSubcustomers = searchCustomersFactory('searchCustomersWithoutSubcustomers')
export const searchCustomers = searchCustomersFactory('searchCustomers')

export const subcustomersByAlystraId = (alystraId: string) => (dispatch: ThunkDispatch) => {
  return dispatch(
    api.asyncQuery(GET_SUBCUSTOMERS, {
      query: 'subcustomersForAlystraId',
      params: {
        alystraId
      }
    })
  )
}

export const searchCustomerAllCountries =
  (searchPhrase: string, departmentIds?: DepartmentIdType[]) => (dispatch: ThunkDispatch) => {
    return dispatch(
      api.asyncQuery(SEARCH_CUSTOMERS, {
        query: 'searchCustomerAllCountries',
        params: {
          searchPhrase,
          departmentIds
        }
      })
    )
  }

export const searchOrders =
  (params: ShipmentSearchParams, departmentIds: List<DepartmentIdType>) => (dispatch: ThunkDispatch) => {
    return dispatch(
      api.asyncQuery(SEARCH_ORDERS, {
        query: 'searchOrders',
        params: {
          searchPhrase: params.searchPhrase || undefined,
          searchOperator: params.searchOperator || 'AND',
          states: params.states || undefined,
          fromDate: params.fromDate || undefined,
          toDate: params.toDate || undefined,
          advancedSearch: params.advancedFilter || undefined,
          departmentIds: departmentIdsToList(departmentIds)
        }
      })
    )
  }

export const getUnconfirmedOrders =
  (params: DateParams, departmentIds: List<DepartmentIdType>) => (dispatch: ThunkDispatch) => {
    return dispatch(
      api.asyncQuery(GET_UNCONFIRMED_ORDERS, {
        query: 'unconfirmedOrders',
        params: {
          fromDate: params.fromDate || undefined,
          toDate: params.toDate || undefined,
          departmentIds: departmentIdsToList(departmentIds)
        }
      })
    )
  }

export const acceptOrders = (selectedOrderIds: number[]) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(ACCEPT_ORDERS, {
      type: 'ACCEPT_ORDERS',
      payload: {
        orderIds: selectedOrderIds
      }
    })
  )

export const rejectOrders =
  (selectedOrderIds: number[], reason: string, comment: string) => (dispatch: ThunkDispatch) =>
    dispatch(
      api.asyncCommand(REJECT_ORDERS, {
        type: 'REJECT_ORDERS',
        payload: {
          orderIds: selectedOrderIds,
          reason,
          otherReason: comment
        }
      })
    )

export const batchUpdateConsignments = (jsonBody: string) => (dispatch: ThunkDispatch) => {
  return dispatch({
    type: BATCH_CONSIGNMENT_UPDATE,
    isEntity: true,
    body: jsonBody
  })
}

export const searchConsignments =
  (
    customerId: number,
    searchPhrase: string,
    fromDate: string,
    toDate: string,
    includeEvents: boolean = true,
    departmentIds: List<DepartmentIdType>,
    customerAlystraId: string,
    state: string | undefined,
    searchDeletedOrders: boolean = false,
    withoutDateOrders: boolean = false,
    includeOrdersWithoutDate: boolean = false,
    includePreAdvice: boolean = false,
    onlyFailedPreAdvice: boolean = false,
    onlyReturnPreAdvice: boolean = false
  ) =>
  (dispatch: ThunkDispatch) => {
    const params: any = {}
    customerId && (params.customerId = customerId)
    params.includeEvents = includeEvents
    params.searchPhrase = searchPhrase ? searchPhrase : ''
    params.includeOrdersWithoutDate = includeOrdersWithoutDate
    params.onlyFailedPreAdvice = onlyFailedPreAdvice
    params.onlyReturnPreAdvice = onlyReturnPreAdvice
    params.includePreAdvice = includePreAdvice || onlyFailedPreAdvice || onlyReturnPreAdvice
    if (!withoutDateOrders) {
      params.fromDate = fromDate || DateTime.local().toISODate()
      params.toDate = toDate || DateTime.local().toISODate()
    }
    state && (params.state = state)
    withoutDateOrders && (params.withoutDateOrders = withoutDateOrders)
    departmentIds && isNotEmpty(departmentIds) && (params.departmentIds = departmentIdsToList(departmentIds))
    customerAlystraId && customerAlystraId !== 'null' ? (params.customerAlystraId = customerAlystraId) : ''

    params.searchOperator = 'OR'

    return searchDeletedOrders
      ? dispatch(api.asyncQuery(SEARCH_DELETED_ORDERS, { query: 'searchDeletedOrders', params: params }))
      : dispatch(api.asyncQuery(SEARCH_CONSIGNMENTS, { query: 'searchConsignments', params: params }))
  }

export const getConsignments = (
  departmentIds: number | List<DepartmentIdType>,
  fromDate?: string,
  toDate?: string,
  includeEvents: boolean = true,
  state?: string,
  consignmentIds?: List<ConsignmentIdType>,
  slotIds?: List<SlotIdType>
) => {
  const params: any = {}
  departmentIds && (params.departmentIds = departmentIdsToList(departmentIds))
  fromDate && (params.fromDate = fromDate)
  toDate && (params.toDate = toDate)
  params.includeEvents = includeEvents
  state && (params.state = state)
  consignmentIds && (params.consignmentIds = consignmentIds)
  slotIds && (params.slotIds = slotIds)

  return fetchIfNotPresent(GET_CONSIGNMENTS, { query: 'consignments', params: params })
}

export const getUnresolvedAddresses = (departmentId: number) => (dispatch: ThunkDispatch) =>
  dispatch(api.asyncQuery(GET_UNRESOLVED_ADDRESSES, { query: 'unresolvedAddresses', params: { departmentId } }))

export const getUnresolvedAddressCount = (departmentId?: DepartmentIdType) => (dispatch: ThunkDispatch) => {
  if (departmentId) {
    return dispatch(
      api.asyncQuery(GET_UNRESOLVED_ADDRESS_COUNT, {
        query: 'unresolvedAddressCount',
        params: { departmentId: departmentId }
      })
    )
  }
}

export const getUnconfirmedOrdersCount = (departmentIds: Set<DepartmentIdType>) => (dispatch: ThunkDispatch) => {
  if (departmentIds.size > 0) {
    return dispatch(
      api.asyncQuery(GET_UNCONFIRMED_ORDERS_COUNT, {
        query: 'unconfirmedOrdersCount',
        params: { departmentIds }
      })
    )
  }
}

export const getTemplatesByDepartmentIds = (departmentIds: List<DepartmentIdType>) =>
  fetchIfNotPresent(GET_TEMPLATES, { query: 'templatesByDepartmentIds', params: { ids: departmentIds } })

export const saveTemplate =
  (
    template: { department: number; name: string; id: number | null; default?: boolean },
    templateSlots: List<{ slot: number }>
  ) =>
  (dispatch: ThunkDispatch) => {
    return dispatch(
      api.asyncCommand(CREATE_OR_UPDATE_TEMPLATE, {
        type: 'CREATE_OR_UPDATE_TEMPLATE',
        payload: {
          template: template,
          templateSlots: templateSlots
        }
      })
    )
  }

export type SetDepartmentDefaultTemplates = { defaults: { department: number; template: number | null }[] }
export const setDepartmentDefaultTemplates = (payload: SetDepartmentDefaultTemplates) =>
  api.asyncCommand(SET_DEPARTMENT_DEFAULT_TEMPLATES, { type: SET_DEPARTMENT_DEFAULT_TEMPLATES.name, payload })

export const createSlotsFromTemplate =
  (templateId: number, departmentId: number, date: Date | string) => (dispatch: ThunkDispatch) => {
    return dispatch(
      api.asyncCommand(CREATE_SLOTS_FROM_TEMPLATE, {
        type: 'CREATE_SLOTS_FROM_TEMPLATE',
        payload: {
          id: templateId,
          department: departmentId,
          date: date
        }
      })
    )
  }

export const deleteTemplate = (templateId: number) => (dispatch: ThunkDispatch) => {
  if (confirm(i18next.t('planner.templateDeleteConfirm'))) {
    return deleteTemplateRequest(templateId)(dispatch)
  }
}

export const deleteTemplateRequest = (templateId: number) => (dispatch: ThunkDispatch) => {
  return dispatch(
    api.asyncCommand(DELETE_TEMPLATE, {
      type: 'DELETE_TEMPLATE',
      payload: {
        id: templateId
      }
    })
  )
}

export const exportForInvoicing = (
  departmentId: number,
  fromDate: string,
  toDate: string,
  isRemoveControlColumnsChecked?: boolean
) =>
  api
    .fetchPost('/query', {
      query: 'export',
      params: {
        department: departmentId,
        fromDate: fromDate || DateTime.local().toISODate(),
        toDate: toDate || DateTime.local().toISODate(),
        isRemoveControlColumnsChecked
      }
    })
    .then((response) => response.blob())
    .then((blob) => saveAs(blob, 'export.csv'))

export const getSlotById = (slotId: number, shallowFetch: boolean = false) =>
  fetchIfNotPresent(GET_SLOT, { query: 'slotById', params: { id: slotId, shallowFetch: shallowFetch } })

export const getUnits = (departmentIds: Set<DepartmentIdType>) => {
  const params = {
    departmentIds: departmentIds.toArray()
  }
  return fetchIfNotPresent(GET_UNITS, { query: 'units', params: params })
}

export const cancelOptimizingRequest = (id: number) => (dispatch: ThunkDispatch) => {
  dispatch(
    api.asyncCommand(CANCEL_RUNNING_OPTIMIZING, {
      type: 'CANCEL_RUNNING_OPTIMIZING',
      payload: { id: id }
    })
  )
}

export const getOptimizingData = (date: string, limit: boolean) =>
  fetchIfNotPresent(GET_OPTIMIZING_DATA, {
    query: 'optimizingData',
    params: {
      from: date,
      limit
    }
  })

export const getApolloAuditLog = (date: string) =>
  fetchIfNotPresent(GET_APOLLO_AUDIT_LOG, {
    query: 'apolloAuditLogEntries',
    params: {
      date: date
    }
  })

export const getOptimizingRequestData = (requestId: number) =>
  fetchIfNotPresent(GET_OPTIMIZING_REQUEST_DATA, {
    query: 'optimizingRequestData',
    params: {
      id: requestId
    }
  })

export const getOptimizingResponseData = (requestId: number) =>
  fetchIfNotPresent(GET_OPTIMIZING_RESPONSE_DATA, {
    query: 'optimizingResponseData',
    params: {
      id: requestId
    }
  })

export const getOptimizingErrorData = (requestId: number) =>
  fetchIfNotPresent(GET_OPTIMIZING_ERROR_DATA, {
    query: 'optimizingErrorData',
    params: {
      id: requestId
    }
  })

export const getCourierLocationsByDepartmentId = (departmentIds: number | number[] | List<number>) =>
  fetchIfNotPresent(GET_COURIER_LOCATIONS, {
    query: 'courierLocationsByDepartmentId',
    params: { id: departmentIdsToList(departmentIds) }
  })

export const getCourierLocationsBySlotId = (slotId: SlotIdType) =>
  fetchIfNotPresent(GET_COURIER_LOCATIONS, { query: 'courierLocationsBySlotId', params: { slotId } })

export const getTexts = (groupKey = 'SERVICE_MATRIX') =>
  fetchIfNotPresent(GET_TEXTS, {
    query: 'texts',
    params: {
      group: groupKey
    }
  })

export const getTextById = (id: number) =>
  fetchIfNotPresent(GET_TEXT_BY_ID, {
    query: 'getTextById',
    params: {
      id
    }
  })

export const createText = (payload: AnyData) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(ADD_TEXT, {
      type: ADD_TEXT.name,
      payload: payload
    })
  )

export const updateTextRequest = (payload: AnyData) => (dispatch: ThunkDispatch) => {
  return dispatch(
    api.asyncCommand(UPDATE_TEXT, {
      type: 'UPDATE_TEXT',
      payload: payload
    })
  )
}

export const deleteTextById = (id: number) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(DELETE_TEXT, {
      type: DELETE_TEXT.name,
      payload: { id: id }
    })
  )

export const openModal = (modalClass: any, props: AnyData) => (dispatch: ThunkDispatch) => {
  dispatch({
    type: MODAL_OPEN,
    className: modalClass,
    props: props
  })
}

export const closeModal =
  (keepState: boolean = false) =>
  (dispatch: ThunkDispatch) => {
    dispatch({
      type: keepState ? MODAL_CLOSE_KEEP_PAGE_STATE : MODAL_CLOSE
    })
  }

export const openDrawer = (drawerClass: string | React.ElementType, props: AnyData) => (dispatch: ThunkDispatch) => {
  dispatch({
    type: DRAWER_OPEN,
    className: drawerClass,
    props: props
  })
}

export const closeDrawer = () => (dispatch: ThunkDispatch) => {
  dispatch({
    type: DRAWER_CLOSE
  })
}

export const setReactRenderError = (error: Error, info: ErrorInfo) => (dispatch: ThunkDispatch) => {
  dispatch({
    type: SET_REACT_RENDER_ERROR,
    error: error,
    info: info
  })
}

export type UnitPayload = {
  weightCapacity: string | null
  color: null | string
  co2: string
  isActive: boolean
  vehicleControlComment: string
  capacity: string
  vehicleBrand: string
  shouldDriverReturn: boolean
  vehicleModel: string
  commercialTrafficEligibility: boolean
  department: number
  serviceTimeFactor: number
  vehicleType: string
  vehicleControl: string
  volumeCapacity: string | null
  break:
    | null
    | { duration: string; type: any; earliest: any; latest: any }
    | {
        duration: string
        maxDrivingTime: string
        type: any
      }
  isUnit: boolean
  speedFactor: number
  alystraId: string
  fuelType: string
  courier: number | null
  courierUserIds: number[]
  registrationNumber: string
  euroClass: string
  name: string
  maxRangeKm: string | null
  location: { start: { lng: number; lat: number }; end: { lng: number; lat: number } }
}

export const getUnitPayload = (values: ImmutableMap<UnitFormProps>, departmentId: number): UnitPayload => {
  return {
    name: values.get('name'),
    courier: values.get('courierId') || null,
    capacity: values.get('capacity'),
    weightCapacity: values.get('weightCapacity') || null,
    volumeCapacity: values.get('volumeCapacity') || null,
    speedFactor: values.get('speedFactor')[0],
    serviceTimeFactor: values.get('serviceTimeFactor')[0],
    department: departmentId,
    alystraId: values.get('alystraId'),
    location: {
      start: {
        lng: values.getIn(['startLocation', 'lng']),
        lat: values.getIn(['startLocation', 'lat'])
      },
      end: {
        lng: values.getIn(['endLocation', 'lng']),
        lat: values.getIn(['endLocation', 'lat'])
      }
    },
    shouldDriverReturn: !values.get('shouldDriverNotReturn'),
    isActive: values.get('isActive'),
    vehicleType: values.get('vehicleType'),
    fuelType: values.get('fuelType'),
    color: values.get('color') == '#ffffff' ? null : values.get('color'),
    maxRangeKm: values.get('maxRangeKm') || null,
    courierUserIds: values
      .get('courierUsers')
      ?.toList()
      ?.map((v) => v.get('id'))
      ?.toJS(),
    isUnit: values.get('isUnit') || false,
    registrationNumber: values.get('registrationNumber') || '',
    commercialTrafficEligibility: values.get('commercialTrafficEligibility') || false,
    vehicleBrand: values.get('vehicleBrand') || '',
    vehicleModel: values.get('vehicleModel') || '',
    vehicleControl: values.get('vehicleControl') || '',
    vehicleControlComment: values.get('vehicleControlComment') || '',
    euroClass: values.get('euroClass') || '',
    co2: values.get('co2') || '',
    break: toBreak(values)
  }
}

export enum BreakType {
  NoBreakTime = 'NoBreakTime',
  TimeWindow = 'TimeWindow',
  DriveTime = 'DriveTime'
}

export const toBreak = (values: Map<string, any>) => {
  if (values.get('breakType') === BreakType.NoBreakTime) {
    return null
  } else if (values.get('breakType') === BreakType.TimeWindow) {
    return {
      type: values.get('breakType'),
      duration: Duration.fromDurationLike({ minute: values.get('breakTimeWindowDuration') }).toISO(),
      earliest: values.get('breakEarliest'),
      latest: values.get('breakLatest')
    }
  } else {
    return {
      type: values.get('breakType'),
      duration: Duration.fromDurationLike({ minute: values.get('breakDrivingTimeDuration') }).toISO(),
      maxDrivingTime: Duration.fromDurationLike({ hour: values.get('breakMaxDrivingTime') }).toISO()
    }
  }
}

export const closeModalAndNotify = (
  title: string,
  message: string = '',
  timeout: number = 1000,
  keepState: boolean = false
) => {
  return function (dispatch: ThunkDispatch) {
    dispatch(closeModal(keepState))
    dispatch(addNotification({ title: title, message: message, timeout: timeout }))
  }
}

export const getOrderByShipmentId = (shipmentId: number) =>
  fetchIfNotPresent(GET_ORDER_BY_SHIPMENT_ID, { query: 'orders', params: { shipmentId: shipmentId } })

export const getOrdersByRecurringOrderId = (recurringOrderId: number) =>
  api.asyncQuery(GET_ORDERS_BY_RECURRING_ORDER_ID, { query: 'orders', params: { recurringOrderId } })

export const createOrUpdateOrder =
  (payload: AnyData, onSuccessRedirectUrl: string, push: (url: string) => void) => (dispatch: ThunkDispatch) => {
    const res = dispatch(asyncOptimusCreateOrders(CREATE_ORDER_OPTIMUS, payload))
    return res
      ? res.then(() => {
          push(onSuccessRedirectUrl)
          dispatch(closeModalAndNotify(i18next.t('instant.booking.created')))
        })
      : res
  }

export const createUnit = (values: ImmutableMap<UnitFormProps>, departmentId: number) => {
  return function (dispatch: ThunkDispatch) {
    dispatch(createUnit2(getUnitPayload(values, departmentId))).then(
      (res) => (res.ok ? dispatch(closeModalAndNotify(i18next.t('courier.created'))) : res),
      (err) => console.log('createUnit', err)
    )
  }
}

export const createUnit2 = (payload: UnitPayload) =>
  api.asyncCommand(CREATE_UNIT, {
    type: 'CREATE_UNIT',
    payload
  })

export const createPlannerJS = (values: PlannerProps) => {
  return function (dispatch: ThunkDispatch) {
    return dispatch(
      api.asyncCommand(CREATE_PLANNER, {
        type: 'CREATE_PLANNER',
        payload: { ...values, email: values.email !== '' ? values.email : undefined }
      })
    )
  }
}

export const createCourierUserJS = (values: CreateUpdateCourierFormProps, departmentId: number | undefined) => {
  return function (dispatch: ThunkDispatch) {
    return dispatch(
      api.asyncCommand(CREATE_COURIER_USER, {
        type: 'CREATE_COURIER_USER',
        payload: {
          name: values.name,
          phoneNumber: values.phoneNumber,
          canLogIn: values.canLogIn,
          secureLoginEnabled: values.secureLoginEnabled,
          driverId: values.driverId,
          unitIds: [],
          alcoholDeliveryEducation: values.alcoholDeliveryEducation,
          departmentId: departmentId,
          email: values.email,
          dateOfBirth: values.dateOfBirth,
          occupationalInjuryInsurance: values.occupationalInjuryInsurance,
          courierScanEnabled: values.courierScanEnabled
        }
      })
    )
  }
}

export const updateCourierUserJS = (values: CreateUpdateCourierFormProps, departmentId: number | undefined) => {
  return function (dispatch: ThunkDispatch) {
    return dispatch(
      api.asyncCommand(UPDATE_COURIER_USER, {
        type: 'UPDATE_COURIER_USER',
        payload: {
          id: values.id,
          name: values.name,
          phoneNumber: values.phoneNumber,
          canLogIn: values.canLogIn,
          secureLoginEnabled: values.secureLoginEnabled,
          driverId: values.driverId,
          unitIds: [],
          alcoholDeliveryEducation: values.alcoholDeliveryEducation,
          departmentId: departmentId,
          email: values.email,
          dateOfBirth: values.dateOfBirth,
          occupationalInjuryInsurance: values.occupationalInjuryInsurance,
          courierScanEnabled: values.courierScanEnabled
        }
      })
    )
  }
}

export const createAirExpressCustomsAgent = (values: CustomsAgentFormValues) => {
  return function (dispatch: ThunkDispatch) {
    dispatch(
      api.asyncCommand(CREATE_PLANNER, {
        type: 'CREATE_PLANNER',
        payload: {
          name: values.name,
          phoneNumber: values.phoneNumber,
          departments: values.departments,
          roleType: 'customs_agent'
        }
      })
    ).then(
      (res) => (res.ok ? dispatch(closeModalAndNotify(i18next.t('airexpress.customsAgent.created'))) : res),
      (err) => console.log('createCustomsAgent', err)
    )
  }
}

export const createDepartmentRequest = (values: DepartmentFormValues) => {
  return function (dispatch: ThunkDispatch) {
    return dispatch(
      api.asyncCommand(CREATE_DEPARTMENT, {
        type: 'CREATE_DEPARTMENT',
        payload: { department: formatDepartmentPayload(values) }
      })
    )
  }
}

export const updateDepartmentRequest = (values: DepartmentFormValues) => {
  return function (dispatch: ThunkDispatch) {
    return dispatch(
      api.asyncCommand(UPDATE_DEPARTMENT, {
        type: 'UPDATE_DEPARTMENT',
        payload: { department: formatDepartmentPayload(values) }
      })
    )
  }
}

const formatDepartmentPayload = (values: DepartmentFormValues) => {
  // Convert form fields that need more than primitives.
  const payload: { [key: string]: unknown } = {
    emailAddresses: values.emailAddress ? [values.emailAddress] : null,
    location: { lat: values.lat, lng: values.lng },
    npsId: parseInt(values.npsId, 10)
  }

  // Convert empty strings from blank form fields into null values for the backend.
  for (const [k, v] of nullEmptyStrings(Object.entries(values))) {
    payload[k] = v
  }

  return payload
}

const nullEmptyStrings = (input: [any, any][]): (readonly [any, unknown])[] =>
  input.map(([k, v]) => {
    const newV = nullEmptyString(v)
    return [k, newV]
  })

const nullEmptyString = (v: unknown): unknown => {
  if (v === null) {
    return null
  } else if (v === '') {
    return null
  } else if (Array.isArray(v)) {
    return v.map((value) => nullEmptyString(value))
  } else if (typeof v === 'object') {
    return Object.fromEntries(nullEmptyStrings(Object.entries(v)))
  } else return v
}

interface DepartmentTimeWindowProps {
  id?: number
  departmentId?: number
  day: string
  cutoff: string
  pickupEarliest: string
  pickupLatest: string
  deliveryEarliest: string
  deliveryLatest: string
  type: string
}

export function upsertWindows(departmentId: DepartmentIdType | undefined, windows: DepartmentTimeWindowProps[]) {
  return function (dispatch: ThunkDispatch) {
    // Dispatch one upsert command for each time window.
    const payload = windows.map((window) => ({
      ...window,
      departmentId: window.departmentId || departmentId,
      day: window.day.includes('sameday') ? window.day.split('_').pop() : window.day,
      type: window.day.includes('sameday') ? 'H2_SameDay' : 'H2'
    }))

    return dispatch(
      checkoutApi.asyncCommand(UPSERT_DEPARTMENT_TIME_WINDOW, {
        type: 'UPSERT_DEPARTMENT_TIME_WINDOW',
        payload
      })
    )
  }
}

export const updatePlannerJS = (values: PlannerProps) => {
  return function (dispatch: ThunkDispatch) {
    return dispatch(
      api.asyncCommand(UPDATE_PLANNER, {
        type: 'UPDATE_PLANNER',
        payload: { ...values, email: values.email !== '' ? values.email : undefined }
      })
    )
  }
}
export const removeRolesFromPlanner = (roleIds: number[], userId: number) => {
  return function (dispatch: ThunkDispatch) {
    return dispatch(
      api.asyncCommand(REMOVE_ROLES_FROM_USER, {
        type: 'REMOVE_ROLES_FROM_USER',
        payload: { roleIds, userId }
      })
    )
  }
}
export const addRolesToPlanner = (roleIds: number[], userId: number) => {
  return function (dispatch: ThunkDispatch) {
    return dispatch(
      api.asyncCommand(ADD_ROLES_TO_USER, {
        type: 'ADD_ROLES_TO_USER',
        payload: { roleIds, userId }
      })
    )
  }
}

export const updateAirExpressCustomsAgent = (values: CustomsAgentFormValues) => {
  return function (dispatch: ThunkDispatch) {
    dispatch(
      api.asyncCommand(UPDATE_PLANNER, {
        type: 'UPDATE_PLANNER',
        payload: {
          planner: values.id,
          name: values.name,
          phoneNumber: values.phoneNumber,
          departments: values.departments,
          roleType: 'customs_agent',
          canLogIn: values.canLogIn
        }
      })
    ).then(
      (res) => (res.ok ? dispatch(closeModalAndNotify(i18next.t('airexpress.customsAgent.updated'))) : res),
      (err) => console.log('updateCustomsAgent', err)
    )
  }
}

export const updateAreaInSlot = (slotId: number, area: any) => (dispatch: ThunkDispatch) => {
  return dispatch(
    api.asyncCommand(UPDATE_AREA_IN_SLOT, { type: 'UPDATE_AREA_IN_SLOT', payload: { slot: slotId, area: area } })
  )
}

export const removeAreaFromSlot = (slotId: number) => (dispatch: ThunkDispatch) => {
  return dispatch(api.asyncCommand(REMOVE_AREA_FROM_SLOT, { type: 'REMOVE_AREA_FROM_SLOT', payload: { slot: slotId } }))
}

export const createRoute = (departmentId: number, slotData: Slot) => (dispatch: ThunkDispatch) => {
  return dispatch(
    api.asyncCommand(CREATE_ROUTE, { type: 'CREATE_ROUTE', payload: { department: departmentId, slot: slotData } })
  )
}

export const updateRouteCommand = (departmentId: number, slotData: Slot) => (dispatch: ThunkDispatch) => {
  return dispatch(
    api.asyncCommand(UPDATE_ROUTE, { type: 'UPDATE_ROUTE', payload: { department: departmentId, slot: slotData } })
  )
}

export const updateRoute = (departmentId: number, slotData: Slot) => (dispatch: ThunkDispatch) => {
  return updateRouteCommand(
    departmentId,
    slotData
  )(dispatch).then((res) => (res.ok ? dispatch(closeModalAndNotify(i18next.t('planner.routeUpdated'))) : res))
}

export const deleteSlot =
  (slotId: number, onSuccessRedirectUrl?: string, push?: (url: string) => void) => (dispatch: ThunkDispatch) => {
    return dispatch(
      api.asyncDeleteCommand(
        DELETE_SLOT,
        { type: 'DELETE_SLOT', payload: { slot: slotId } },
        onSuccessRedirectUrl,
        push
      )
    )
  }

export const deleteUser =
  (userId: number, onSuccessRedirectUrl?: string, push?: (url: string) => void) => (dispatch: ThunkDispatch) => {
    return dispatch(
      api.asyncDeleteCommand(
        DELETE_USER,
        { type: 'DELETE_USER', payload: { userId: userId } },
        onSuccessRedirectUrl,
        push
      )
    )
  }

export const completeSlotPlanning = (slotId: number) => (dispatch: ThunkDispatch) => {
  return dispatch(
    api.asyncCommand(COMPLETE_SLOT_PLANNING, { type: COMPLETE_SLOT_PLANNING.name, payload: { slot: slotId } })
  )
}

export const deleteOrders =
  (orderIds: Set<number>, onSuccessRedirectUrl?: string, push?: (url: string) => void) => (dispatch: ThunkDispatch) => {
    return dispatch(
      api.asyncDeleteCommand(
        DELETE_ORDERS,
        { type: 'DELETE_ORDERS', payload: { orderIds: orderIds } },
        onSuccessRedirectUrl,
        push
      )
    )
  }

export const copyDeviatedOrder =
  (orderId: number, notifyOnResponse = true) =>
  (dispatch: ThunkDispatch) => {
    return dispatch(api.asyncCommand(COPY_DEVIATED_ORDER, { type: 'COPY_DEVIATED_ORDER', payload: { orderId } })).then(
      (res) => {
        if (!notifyOnResponse || !res.ok) {
          return res
        }

        dispatch(closeModalAndNotify(i18next.t('consignment.orderDuplicated')))
      },
      (err) => {
        console.log('COPY_DEVIATED_ORDER', err)

        if (notifyOnResponse) {
          dispatch(
            addNotification({ title: i18next.t('consignment.orderDuplicatedFailed'), message: '', timeout: 10000 })
          )
        }
      }
    )
  }

export const returnToSender =
  (orderId: number, notifyOnResponse = true) =>
  (dispatch: ThunkDispatch) => {
    return dispatch(createMultilegReturn(orderId)).then(
      (res) => {
        if (!res.ok) return false
        if (notifyOnResponse) {
          dispatch(closeModalAndNotify(i18next.t('manageReturns.returnToSenderQueued')))
        }
        return true
      },
      (err) => {
        console.log('CREATE_MULTILEG_RETURN', err)

        if (notifyOnResponse) {
          dispatch(
            addNotification({ title: i18next.t('manageReturns.returnToSenderFailed'), message: '', timeout: 10000 })
          )
        }

        return false
      }
    )
  }

export const reversedOptimizeSlot = (slot: number) => (dispatch: ThunkDispatch) => {
  return dispatch(api.asyncCommand(REVERSED_OPTIMIZE_SLOT, { type: 'REVERSED_OPTIMIZE_SLOT', payload: { slot: slot } }))
}

export const createNumberSeries = (type: string, current: number, max: number) => (dispatch: ThunkDispatch) => {
  return dispatch(
    api.asyncCommand(CREATE_NUMBER_SERIES, {
      type: 'CREATE_NUMBER_SERIES',
      payload: { type: type, currentNumber: current, maxNumber: max }
    })
  )
}

export const deleteNumberSeries = (id: number) => (dispatch: ThunkDispatch) => {
  return dispatch(api.asyncCommand(DELETE_NUMBER_SERIES, { type: 'DELETE_NUMBER_SERIES', payload: { id: id } }))
}

export const uploadExcelOrdersV2 = (file: File, departmentId: number) => (dispatch: ThunkDispatch) => {
  const formData = new FormData()
  formData.append('departmentId', toString(departmentId))
  formData.append('file', file)

  return dispatch(api.asyncPostFormData(UPLOAD_EXCEL_ORDERS_V2, '/uploadExcelOrdersV2', formData))
}

export const uploadExcelAddresses = (file: File, customerId?: number) => (dispatch: ThunkDispatch) => {
  const formData = new FormData()
  formData.append('file', file)
  customerId && formData.append('customerId', toString(customerId))

  return dispatch(api.asyncPostFormData(UPLOAD_EXCEL_ADDRESSES, '/uploadExcelAddresses', formData))
}

export const downloadExcelAddressesForCustomer = (
  customerNumber: string,
  subcustomer: string | null,
  language: string
) =>
  api
    .fetchPost('/downloadCustomerAddresses', {
      params: {
        customerNumber,
        subcustomer,
        language
      }
    })
    .then((response) => response.blob())
    .then((blob) => saveAs(blob, `addresses_${customerNumberAndSubcustomer(customerNumber, subcustomer)}.xlsx`))
    .catch((err) => console.error(err))

export const updateConsignmentsPickupLocation = (payload: AnyData) => {
  return function (dispatch: ThunkDispatch) {
    updateConsignmentsPickupLocationNew(payload)(dispatch).then(
      (res) => (res.ok ? dispatch(closeModalAndNotify(i18next.t('consignment.updated'))) : res),
      (err) => console.log('updateConsignmentsPickupLocation', err)
    )
  }
}

export const updateConsignmentsPickupLocationNew = (payload: AnyData) => (dispatch: ThunkDispatch) => {
  return dispatch(
    api.asyncCommand(UPDATE_CONSIGNMENTS_PICKUP_LOCATION, {
      type: UPDATE_CONSIGNMENTS_PICKUP_LOCATION.name,
      payload: payload
    })
  )
}

export const setCourierLocation =
  (unitId: number, lat: number, lng: number, accuracy: string, timestampIso: string) => (dispatch: ThunkDispatch) => {
    return dispatch(
      api.asyncCommand(SET_COURIER_LOCATION, {
        type: 'SET_COURIER_LOCATION',
        payload: {
          unit: unitId,
          location: {
            lat: lat,
            lng: lng,
            accuracy: accuracy,
            time: timestampIso
          }
        }
      })
    )
  }

export const resolveAddress =
  (unresolvedId: UnresolvedAddressIdType, lat: string, lng: string, departmentId: DepartmentIdType) =>
  (dispatch: ThunkDispatch) => {
    return dispatch(
      api.asyncCommand(RESOLVE_ADDRESS, {
        type: 'RESOLVE_ADDRESS',
        payload: { unresolvedAddressId: unresolvedId, location: { lat: lat, lng: lng } }
      })
    ).then(
      (res) => {
        if (res.ok) {
          dispatch(getUnresolvedAddressCount(departmentId))?.then()
          dispatch(getUnresolvedAddresses(departmentId))?.then()
        } else return res
      },
      (err) => console.log('resolveAddress', err)
    )
  }

export const isReadOnlyUser = (user: SessionUser) => user.get('role') === ROLE_READ_ONLY_PLANNER

export const resetAllHttpStatus = () => (dispatch: ThunkDispatch) => {
  dispatch({ type: CLEAR_ALL_HTTP_STATUS })
  dispatch({ type: CLEAR_AGGREGATES })
  dispatch({ type: CLEAR_OPTIMIZING_DATA })
  dispatch({ type: CLEAR_APOLLO_AUDIT_LOG })
}

export const markHttpFailuresAsRead = () => (dispatch: ThunkDispatch) => {
  dispatch({ type: HTTP_FAILURE_ERRORS_READ })
}

export const unassignBulkConsignmentsFromSlotsDispatch =
  (consignmentIds: Set<ConsignmentIdType>) => (dispatch: ThunkDispatch) =>
    dispatch(
      api.asyncCommand(UNASSIGN_BULK_CONSIGNMENTS_FROM_SLOTS, {
        type: UNASSIGN_BULK_CONSIGNMENTS_FROM_SLOTS.name,
        payload: {
          consignments: consignmentIds
        }
      })
    )

export const unassignBulkConsignmentsFromSlots =
  (consignmentIds: Set<ConsignmentIdType>) => (dispatch: ThunkDispatch) =>
    unassignBulkConsignmentsFromSlotsDispatch(consignmentIds)(dispatch).then(
      (res) =>
        res.ok
          ? dispatch(
              addNotification({
                title: i18next.t('planner.unassignBulkConsignmentsFromSlots'),
                message: '',
                timeout: 10000
              })
            )
          : res,
      (err) => console.log('unassignBulkConsignmentsFromSlots', err)
    )

export const plainUnassignConsignmentsFromSlot =
  (slotId: number, consignmentIds: Set<number>) => (dispatch: ThunkDispatch) => {
    return dispatch(
      api.asyncCommand(UNASSIGN_CONSIGNMENT_FROM_SLOT, {
        type: 'UNASSIGN_CONSIGNMENT_FROM_SLOT',
        payload: {
          consignments: consignmentIds,
          slot: slotId
        }
      })
    )
  }
export const unassignConsignmentsFromSlot =
  (slotId: number, consignmentIds: Set<number>) => (dispatch: ThunkDispatch) => {
    if (confirm(i18next.t('planner.unassignConsignmentFromSlot'))) {
      return dispatch(
        api.asyncCommand(UNASSIGN_CONSIGNMENT_FROM_SLOT, {
          type: 'UNASSIGN_CONSIGNMENT_FROM_SLOT',
          payload: {
            consignments: consignmentIds,
            slot: slotId
          }
        })
      ).then(
        (res) =>
          res.ok
            ? dispatch(
                addNotification({
                  title: i18next.t('planner.unassignedFromSlot'),
                  message: '',
                  timeout: 10000
                })
              )
            : res,
        (err) => console.log('unassignConsignmentFromSlot', err)
      )
    }
  }

export const deleteBreakFromRoute = (id: number) => (dispatch: ThunkDispatch) => {
  return dispatch(
    api.asyncCommand(DELETE_BREAK_FROM_ROUTE, {
      type: 'DELETE_BREAK_FROM_ROUTE',
      payload: {
        id: id
      }
    })
  ).then(
    (res) =>
      res.ok
        ? dispatch(addNotification({ title: i18next.t('planner.breakDeletedFromRoute'), message: '', timeout: 10000 }))
        : res,
    (err) => console.log('breakDeletedFromRoute', err)
  )
}

export const unassignConsignmentsDispatch = (consignmentIds: Set<number>) => (dispatch: ThunkDispatch) => {
  return dispatch(
    api.asyncCommand(UNASSIGN_CONSIGNMENTS, {
      type: 'UNASSIGN_CONSIGNMENTS',
      payload: {
        consignments: consignmentIds
      }
    })
  )
}

export const unassignConsignments = (consignmentIds: Set<number>) => (dispatch: ThunkDispatch) => {
  return unassignConsignmentsDispatch(consignmentIds)(dispatch).then(
    (res) =>
      res.ok
        ? dispatch(
            addNotification({
              title: i18next.t('planner.unassignBulkConsignmentsFromSlots'),
              message: '',
              timeout: 10000
            })
          )
        : res,
    (err) => {
      console.log('unassignConsignments', err)
      dispatch(addNotification({ title: 'Unable to unassign', message: '', timeout: 10000 }))
    }
  )
}

export const forceUnassignConsignmentsDispatch = (consignmentIds: Set<number>) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(FORCE_UNASSIGN_CONSIGNMENTS, {
      type: 'FORCE_UNASSIGN_CONSIGNMENTS',
      payload: {
        consignments: consignmentIds
      }
    })
  )

export const forceUnassignConsignments = (consignmentIds: Set<number>) => (dispatch: ThunkDispatch) => {
  return forceUnassignConsignmentsDispatch(consignmentIds)(dispatch).then(
    (res) =>
      res.ok
        ? dispatch(
            addNotification({
              title: i18next.t('planner.unassignedFromSlot'),
              message: '',
              timeout: 5000
            })
          )
        : res,
    (err) => {
      console.log('unassignConsignments', err)
      dispatch(addNotification({ title: 'Unable to unassign', message: '', timeout: 10000 }))
    }
  )
}

export const updatePickupAndDeliveryTimes = (payload: AnyData) => (dispatch: ThunkDispatch) => {
  return dispatch(
    api.asyncCommand(UPDATE_PICKUP_AND_DELIVERY_TIMES, {
      type: 'UPDATE_PICKUP_AND_DELIVERY_TIMES',
      payload: payload
    })
  ).then(
    (res) => (res.ok ? dispatch(closeModalAndNotify(i18next.t('consignment.updated'))) : res),
    (err) => {
      console.log('update delivery times', err)
      dispatch(addNotification({ title: 'Unable to update', message: '', timeout: 10000 }))
    }
  )
}

export const changeSlotForConsignments =
  (value: Map<string, any>, fromSlotId: number, consignmentIds: Set<number>) => (dispatch: ThunkDispatch) => {
    if (confirm(i18next.t('planner.alterSlotIdOnConsignment'))) {
      return dispatch(
        api.asyncCommand(SET_CONSIGNMENT_SLOT, {
          type: 'UPDATE_CONSIGNMENT_SLOT',
          payload: {
            consignments: consignmentIds,
            slot: value.get('slot'),
            oldSlotId: fromSlotId
          }
        })
      ).then(
        (res) => (res.ok ? dispatch(closeModalAndNotify(i18next.t('consignment.updated'))) : res),
        (err) => console.log('changeSlotForConsignment', err)
      )
    }
  }

export const plainChangeSlotForConsignments =
  (slot: string, fromSlotId: number, consignmentIds: Set<number>) => (dispatch: ThunkDispatch) => {
    return dispatch(
      api.asyncCommand(SET_CONSIGNMENT_SLOT, {
        type: 'UPDATE_CONSIGNMENT_SLOT',
        payload: {
          consignments: consignmentIds,
          slot,
          oldSlotId: fromSlotId
        }
      })
    )
  }

export const plainAddConsignmentsToSlotAndReoptimize =
  (slot: string, consignmentIds: Set<number>) => (dispatch: ThunkDispatch) => {
    return dispatch(
      api.asyncCommand(ADD_CONSIGNMENT_TO_SLOT_AND_REOPTIMIZE, {
        type: 'ADD_CONSIGNMENT_TO_SLOT_AND_REOPTIMIZE',
        payload: {
          consignments: consignmentIds,
          slot
        }
      })
    )
  }
export const addConsignmentsToSlotAndReoptimize =
  (value: Map<string, any>, consignmentIds: Set<number>) => (dispatch: ThunkDispatch) => {
    if (confirm(i18next.t('planner.alterSlotIdOnConsignment'))) {
      return dispatch(
        api.asyncCommand(ADD_CONSIGNMENT_TO_SLOT_AND_REOPTIMIZE, {
          type: 'ADD_CONSIGNMENT_TO_SLOT_AND_REOPTIMIZE',
          payload: {
            consignments: consignmentIds,
            slot: value.get('slot')
          }
        })
      ).then(
        (res) => (res.ok ? dispatch(closeModalAndNotify(i18next.t('consignment.optimizingNewSlot'))) : res),
        (err) => console.log('addConsignmentToSlotAndReoptimize', err)
      )
    }
  }

export const manuallyDeliverConsignments =
  (consignmentIds: Set<number>, timestamp: string, selectedUnitId: string, selectedUserId: string, name: string) =>
  (dispatch: ThunkDispatch) =>
    dispatch(
      api.asyncCommand(DELIVER_CONSIGNMENT_MANUAL_OVERRIDE, {
        type: 'DELIVER_CONSIGNMENT_MANUAL_OVERRIDE',
        payload: {
          consignments: consignmentIds,
          timestamp: timestamp,
          recipientNameText: name,
          unitId: selectedUnitId,
          userId: selectedUserId
        }
      })
    )

export const manuallyReturnConsignments =
  (
    consignmentIds: Set<number>,
    timestamp: string,
    selectedUnitId: string,
    selectedUserId: string,
    lmReturnCode: string,
    recipientNameText?: string
  ) =>
  (dispatch: ThunkDispatch) =>
    dispatch(
      api.asyncCommand(RETURN_CONSIGNMENTS_MANUAL_OVERRIDE, {
        type: 'RETURN_CONSIGNMENTS_MANUAL_OVERRIDE',
        payload: {
          consignments: consignmentIds,
          timestamp: timestamp,
          returnCode: lmReturnCode,
          recipientNameText,
          unitId: selectedUnitId,
          userId: selectedUserId
        }
      })
    )

export const manuallyDeviateConsignments =
  (
    consignmentIds: Set<number>,
    timestamp: string,
    selectedUnitId: string,
    selectedUserId: string,
    deviationCode: string
  ) =>
  (dispatch: ThunkDispatch) =>
    dispatch(
      api.asyncCommand(DEVIATE_CONSIGNMENT_MANUAL_OVERRIDE, {
        type: 'DEVIATE_CONSIGNMENT_MANUAL_OVERRIDE',
        payload: {
          consignments: consignmentIds,
          timestamp: timestamp,
          deviation: deviationCode,
          unitId: selectedUnitId,
          userId: selectedUserId
        }
      })
    )

export const manuallyCollectConsignments =
  (consignmentIds: Set<number>, timestamp: string, selectedUnitId: string, selectedUserId: string) =>
  (dispatch: ThunkDispatch) =>
    dispatch(
      api.asyncCommand(COLLECT_CONSIGNMENTS_MANUAL_OVERRIDE, {
        type: 'COLLECT_CONSIGNMENTS_MANUAL_OVERRIDE',
        payload: {
          consignments: consignmentIds,
          timestamp: timestamp,
          unitId: selectedUnitId,
          userId: selectedUserId
        }
      })
    )

export const addSortedEventsToConsignments =
  (consignmentIds: Set<number>, timestamp: string) => (dispatch: ThunkDispatch) =>
    dispatch(
      api.asyncCommand(ADD_SORTED_EVENTS, {
        type: ADD_SORTED_EVENTS.name,
        payload: { consignments: consignmentIds, timestamp: timestamp }
      })
    )

export const markNotArrivedAtDistributingTerminal =
  (consignmentIds: Set<number>, timestamp: string) => (dispatch: ThunkDispatch) =>
    dispatch(
      api.asyncCommand(MARK_NOT_ARRIVED_AT_DISTRIBUTING_TERMINAL, {
        type: MARK_NOT_ARRIVED_AT_DISTRIBUTING_TERMINAL.name,
        payload: { consignmentIds, timestamp }
      })
    )

export const addScannedEventsToConsignments =
  (consignmentIds: Set<number>, timestamp: string, departmentId: number) => (dispatch: ThunkDispatch) =>
    dispatch(
      api.asyncCommand(ADD_SCANNED_EVENTS, {
        type: 'ADD_SCANNED_EVENTS',
        payload: { consignments: consignmentIds, timestamp: timestamp, departmentId: departmentId }
      })
    )

export const addPreAdviceEventToOrders = (orderIds: Set<number>, timestamp: string) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(PRE_ADVICE_MANUAL_ATTEMPT, {
      type: 'PRE_ADVICE_MANUAL_ATTEMPT',
      payload: { orderIds, timestamp }
    })
  )

export const unresolveDeliveryAddress = (orderId: OrderIdType) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(UNRESOLVE_DELIVERY_ADDRESS, {
      type: 'UNRESOLVE_DELIVERY_ADDRESS',
      payload: { orderId: orderId }
    })
  )

export const unresolvePickupAddress = (orderId: OrderIdType) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(UNRESOLVE_PICKUP_ADDRESS, {
      type: 'UNRESOLVE_PICKUP_ADDRESS',
      payload: { orderId: orderId }
    })
  )

export const claimEmail = (email: string) => (dispatch: ThunkDispatch) => {
  return dispatch(api.asyncPostJson(CLAIM_EMAIL, '/auth/claim-email', { email }))
}

export const requestPwdReset = (email: string) => (dispatch: ThunkDispatch) => {
  return dispatch(api.asyncPostJson(REQUEST_PWD_RESET, '/auth/request-pwd-reset', { email }))
}

export const getAllCustomersByIds = (ids: List<CustomerIdType>) =>
  fetchIfNotPresent(GET_ALL_CUSTOMERS, { query: 'allCustomersByIds', params: { ids } })

export const getDeliveryWindows =
  (customerId: number, postalCode: string, alystraArticleCode: string) => (dispatch: ThunkDispatch) => {
    return dispatch(
      api.asyncQuery(GET_DELIVERY_WINDOWS, {
        query: 'deliveryWindows',
        params: { customerId, postalCode, serviceCode: alystraArticleCode }
      })
    )
  }

export const exportLocationListForSlot = (slotId: SlotIdType) =>
  api
    .fetchPost('/exportLocationListForSlot', { params: { slot: slotId } })
    .then((response) => response.blob())
    .then((blob) => saveAs(blob, 'packageLocations.xlsx'))
    .catch((err) => console.error('exportLocationListForSlot', err))

export const exportMultilegReturnsList = (params: AnyData) =>
  api
    .fetchPost('/exportMultilegReturnsList', { params })
    .then((response) => response.blob())
    .then((blob) => saveAs(blob, 'multilegReturnsList.xlsx'))
    .catch((err) => console.error('exportMultilegReturnsList', err))

export const clearSearchSuggestions = (type: string) => (dispatch: ThunkDispatch) =>
  dispatch({
    type: type === AUTOSUGGEST_CUSTOMERS ? CLEAR_SEARCH_CUSTOMERS : CLEAR_SEARCH_ADDRESSES
  })

export const getOrderPrice = (payload: AnyData) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncQuery(GET_ORDER_PRICE_DATA, {
      query: 'orderPrice',
      params: payload.toJS()
    })
  )

export const getMultistopOrderPrice = (groupId: string) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncQuery(GET_MULTISTOP_ORDER_PRICE_DATA, {
      query: 'multiStopOrderPrice',
      params: { groupId }
    })
  )

export const updateCustomerInformation = (customerId: number, information: string) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(UPDATE_CUSTOMER_INFORMATION, {
      type: 'UPDATE_CUSTOMER_INFORMATION',
      payload: {
        customerId: customerId,
        information: information
      }
    })
  )

export const getInvoicingData = (orderId: number) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncQuery(GET_INVOICING_DATA, {
      query: 'invoicingData',
      params: {
        order: orderId
      }
    })
  )

export const moveOrders =
  (orderIds: List<number>, departmentId: number, consignmentIds: List<ConsignmentIdType>) =>
  (dispatch: ThunkDispatch) =>
    dispatch(
      api.asyncCommand(MOVE_ORDERS, {
        type: 'MOVE_ORDERS',
        payload: {
          orderIds: orderIds,
          destinationDepartmentId: departmentId
        }
      })
    ).then(
      (res) => {
        if (res.ok) {
          dispatch(closeModalAndNotify(i18next.t('consignment.moved')))
          dispatch({ type: CLEAR_ORDER_IDS_ON_MOVED_ORDERS, payload: { orderIds, consignmentIds } })
        }
        return res
      },
      (err) => console.log('moveOrder', err)
    )
export const moveShipment = (orderIds: List<OrderIdType>, departmentId: number) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(MOVE_ORDERS_NEW, {
      type: 'MOVE_ORDERS_NEW',
      payload: {
        orderIds: orderIds,
        destinationDepartmentId: departmentId
      }
    })
  )

export const getDepartmentsForSameCountry = (departmentId: number, accessLevel: string) => (dispatch: ThunkDispatch) =>
  dispatch(
    fetchIfNotPresent(GET_DEPARTMENTS_IN_SAME_COUNTRY, {
      query: 'departmentsInSameCountry',
      params: {
        departmentId: departmentId,
        accessLevel: accessLevel
      }
    })
  )

export const createLabel = (orderId: number) => (dispatch: ThunkDispatch) =>
  dispatch(api.asyncCommand(CREATE_LABEL, { type: 'CREATE_LABEL', payload: { orderId } }))

export const getMultipleLabelUrls = (payload: AnyData) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncQuery(GET_MULTIPLE_LABEL, {
      query: 'labelUrls',
      params: payload
    })
  )

export const createLabelsForDepartment =
  (
    departmentId: DepartmentIdType,
    date: ISODateString,
    callback: (labelUrl: Map<number, any>) => void,
    createShipmentLabels?: boolean,
    slotId?: SlotIdType
  ) =>
  (dispatch: ThunkDispatch) =>
    dispatch(
      api.asyncCommand(CREATE_LABELS_FOR_DEPARTMENT, {
        type: CREATE_LABELS_FOR_DEPARTMENT.name,
        payload: { departmentId, date, isShipmentLabel: createShipmentLabels, slot: slotId }
      })
    ).then(
      (res) => (res.ok ? callback(Map(res.readBody.labelUrls)) : res),
      (err) => {
        console.log('Error while trying to create labels for the department', err)
      }
    )

export const createMultipleLabel =
  (payload: AnyData, callback: (labelUrl: Map<number, any>) => void) => (dispatch: ThunkDispatch) =>
    dispatch(
      api.asyncCommand(CREATE_MULTIPLE_LABEL, {
        type: 'CREATE_MULTIPLE_LABEL',
        payload: payload
      })
    ).then(
      (res) => (res.ok ? callback(Map(res.readBody.labelUrls)) : res),
      (err) => {
        console.log('Error while trying to create multiple label', err)
      }
    )

export const createReturnLabels =
  (orderIds: OrderIdType[], departmentId: DepartmentIdType, callback: (labelUrl: Map<number, any>) => void) =>
  (dispatch: ThunkDispatch) =>
    dispatch(
      api.asyncCommand(CREATE_RETURN_LABELS, {
        type: CREATE_RETURN_LABELS.name,
        payload: { orderIds, departmentId }
      })
    ).then(
      (res) => (res.ok ? callback(Map(res.readBody.labelUrls)) : res),
      (err) => {
        console.log('Error while trying to create multiple label', err)
      }
    )

export const saveCustomerEventEmail =
  (customerId: number, customerEventEmails: CustomerEventEmailFormProps[]) => (dispatch: ThunkDispatch) => {
    return dispatch(
      api.asyncCommand(SAVE_CUSTOMER_EVENT_EMAIL, {
        type: SAVE_CUSTOMER_EVENT_EMAIL.name,
        payload: {
          customerId,
          customerEventEmails
        }
      })
    )
  }

export const getCustomerEventEmail = (customerId: number) =>
  fetchIfNotPresent(GET_CUSTOMER_EVENT_EMAIL, { query: 'customerEventEmail', params: { customerId: customerId } })

export const getOrderDeviations = (id: number) =>
  fetchIfNotPresent(GET_ORDER_DEVIATIONS, { query: 'orderDeviations', params: { customerId: id } })

export const getOrderDeviationsByOrderId = (orderId: number) =>
  fetchIfNotPresent(GET_ORDER_DEVIATIONS, { query: 'orderDeviationsByOrderId', params: { orderId } })

export const createOrderDeviation = (payload: object, callback: () => void) => (dispatch: ThunkDispatch) => {
  dispatch(
    api.asyncCommand(CREATE_ORDER_DEVIATION, {
      type: 'CREATE_ORDER_DEVIATION',
      payload: payload
    })
  ).then(
    (res) => (res.ok ? callback() : res),
    (err) => console.log('addCustomerDeviation', err)
  )
}

export const getCustomerSmsText = (id: CustomerIdType, smsType: string) =>
  fetchIfNotPresent(GET_CUSTOMER_SMS_TEXT, { query: 'customerSmsText', params: { customerId: id, smsType } })

export const createUpdateCustomerSmsText = (payload: object) => (dispatch: ThunkDispatch) => {
  return dispatch(
    api.asyncCommand(CREATE_UPDATE_CUSTOMER_SMS_TEXT, {
      type: 'CREATE_UPDATE_CUSTOMER_SMS_TEXT',
      payload: payload
    })
  )
}

export const updateOrderDeviation = (payload: object, callback: () => void) => (dispatch: ThunkDispatch) => {
  return dispatch(
    api.asyncCommand(UPDATE_ORDER_DEVIATION, {
      type: 'UPDATE_ORDER_DEVIATION',
      payload: payload
    })
  ).then(
    (res) => (res.ok ? callback() : res),
    (err) => console.log('updateCustomerDeviation', err)
  )
}

export const deleteOrderDeviation = (id: number) => (dispatch: ThunkDispatch) => {
  dispatch(api.asyncCommand(DELETE_ORDER_DEVIATION, { type: 'DELETE_ORDER_DEVIATION', payload: { id: id } }))
}

export const setCustomerServiceLevel = (payload: object) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(SET_CUSTOMER_SERVICE_LEVEL, {
      type: 'SET_CUSTOMER_SERVICE_LEVEL',
      payload: payload
    })
  )

export const assignUnitToSlot = (slotId: SlotIdType, unitId: UnitIdType) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(ASSIGN_UNIT_TO_SLOT, {
      type: 'ASSIGN_UNIT_TO_SLOT',
      payload: {
        slot: slotId,
        unit: unitId
      }
    })
  )

export const unassignUnitFromSlot = (slotId: SlotIdType, unitId: UnitIdType) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(UNASSIGN_UNIT_FROM_SLOT, {
      type: 'UNASSIGN_UNIT_FROM_SLOT',
      payload: {
        slotId,
        unitId
      }
    })
  )

export const assignVehicleToSlot = (slotId: SlotIdType, vehicleId: VehicleIdType) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(ASSIGN_VEHICLE_TO_SLOT, {
      type: 'ASSIGN_VEHICLE_TO_SLOT',
      payload: {
        slotId,
        vehicleId
      }
    })
  )
export const unassignVehicleToSlot = (slotId: SlotIdType, vehicleId: VehicleIdType) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(UNASSIGN_VEHICLE_FROM_SLOT, {
      type: 'UNASSIGN_VEHICLE_FROM_SLOT',
      payload: {
        slotId,
        vehicleId: vehicleId
      }
    })
  )

export const assignCourierUserToSlot =
  (slotId: SlotIdType, courierUserId: CourierUserId) => (dispatch: ThunkDispatch) =>
    dispatch(
      api.asyncCommand(ASSIGN_COURIER_TO_SLOT, {
        type: 'ASSIGN_COURIER_TO_SLOT',
        payload: {
          courierId: courierUserId,
          slotId
        }
      })
    )

export const unassignCourierUserFromSlot =
  (slotId: SlotIdType, courierUserId: CourierUserId) => (dispatch: ThunkDispatch) =>
    dispatch(
      api.asyncCommand(UNASSIGN_COURIER_FROM_SLOT, {
        type: 'UNASSIGN_COURIER_FROM_SLOT',
        payload: {
          courierId: courierUserId,
          slotId
        }
      })
    )

export const getAppSetting = (settingType: AppSettingsType, callback?: () => void) => (dispatch: ThunkDispatch) =>
  dispatch(api.asyncQuery(GET_APP_SETTING, { query: 'getAppSetting', params: { type: settingType } })).then(
    (res) => (res.ok ? callback && callback() : res),
    (err) => console.log('GET app setting', err)
  )

export const updateAppSetting =
  <T>(settingType: AppSettingsType, settingValue: T, callback: () => void) =>
  (dispatch: ThunkDispatch) => {
    dispatch(
      api.asyncCommand(UPDATE_APP_SETTING, { type: 'UPDATE_APP_SETTING', payload: { settingType, settingValue } })
    ).then(
      (res) => (res.ok ? callback() : res),
      (err) => console.log('Update app setting', err)
    )
  }

export const addOrUpdateVehicleType = (orderIds: List<OrderIdType>, vehicleType: string) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(UPDATE_ORDER_VEHICLE_TYPE, {
      type: 'UPDATE_ORDER_VEHICLE_TYPE',
      payload: {
        orderIds,
        vehicleType
      }
    })
  )

export const sendPrePickupSms = (orderIds: List<OrderIdType>) => (dispatch: ThunkDispatch) =>
  dispatch(api.asyncCommand(SEND_PRE_PICKUP_SMS, { type: 'SEND_PRE_PICKUP_SMS', payload: { orderIds } }))

export const createDepartmentGroup = (values: DepartmentGroup) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(CREATE_DEPARTMENT_GROUP, {
      type: 'CREATE_DEPARTMENT_GROUP',
      payload: {
        name: values.get('name'),
        departments: values.get('departments'),
        description: values.get('description')
      }
    })
  )

export const createDepartmentGroupJS = (values: DepartmentGroupProps) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(CREATE_DEPARTMENT_GROUP, {
      type: 'CREATE_DEPARTMENT_GROUP',
      payload: {
        name: values.name,
        departments: values.departments,
        description: values.description
      }
    })
  )

export const createCustomerGroup = (values: CustomerGroup) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(CREATE_CUSTOMER_GROUP, {
      type: 'CREATE_CUSTOMER_GROUP',
      payload: {
        name: values.get('name'),
        customers: values.get('customers'),
        description: values.get('description')
      }
    })
  )

export const updateCustomerGroup = (values: CustomerGroup) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(UPDATE_CUSTOMER_GROUP, {
      type: 'UPDATE_CUSTOMER_GROUP',
      payload: {
        customerGroup: values.get('id'),
        name: values.get('name'),
        customers: values.get('customers'),
        description: values.get('description')
      }
    })
  )

export const updateDepartmentGroupJS = (values: DepartmentGroupProps) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(UPDATE_DEPARTMENT_GROUP, {
      type: 'UPDATE_DEPARTMENT_GROUP',
      payload: {
        departmentGroup: values.id,
        name: values.name,
        departments: values.departments,
        description: values.description
      }
    })
  )

export const deleteDepartmentGroupRequest = (departmentGroupId: DepartmentGroupIdType) => (dispatch: ThunkDispatch) => {
  return dispatch(
    api.asyncCommand(DELETE_DEPARTMENT_GROUP, {
      type: 'DELETE_DEPARTMENT_GROUP',
      payload: {
        departmentGroup: departmentGroupId
      }
    })
  )
}

export const deleteCustomerGroup = (customerGroupId: CustomerGroupIdType) => (dispatch: ThunkDispatch) => {
  if (confirm(i18next.t('customer.groups.deleteConfirm'))) {
    return dispatch(
      api.asyncCommand(DELETE_CUSTOMER_GROUP, {
        type: 'DELETE_CUSTOMER_GROUP',
        payload: {
          customerGroup: customerGroupId
        }
      })
    ).then(
      (res) => (res.ok ? dispatch(closeModalAndNotify(i18next.t('customer.groups.deleted'))) : res),
      (err) => {
        dispatch(closeModalAndNotify(i18next.t(getRawErrorTexts(fromJS(err.body) as List<IError>).toArray()[0])))
      }
    )
  }
}

export const getDepartmentGroups = (excludeEmpty?: boolean) =>
  fetchIfNotPresent(GET_DEPARTMENT_GROUPS, { query: 'departmentGroups', params: { excludeEmpty } })

export const getCustomerGroups = () => fetchIfNotPresent(GET_CUSTOMER_GROUPS, { query: 'customerGroups', params: {} })

export const getCustomerGroupMembers = (groupId: number) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncQuery(GET_CUSTOMER_GROUP_MEMBERS, {
      query: 'customerGroupMembers',
      params: {
        customerGroupId: groupId
      }
    })
  ).then((response) => {
    dispatch(setPageStateValue('customers', fromJS(response.readBody)))
  })

export const getServicesAndVasesTexts = (serviceVasTextType: ServiceVasText) =>
  fetchIfNotPresent(GET_SERVICES_AND_VASES_TEXTS, {
    query: 'servicesAndVasesTexts',
    params: {
      userLanguage,
      serviceVasTextType
    }
  })

export const getOptimizingIdBySlot = (slotId: number) =>
  fetchIfNotPresent(GET_LATEST_OPTIMIZING_ID_BY_SLOT, {
    query: 'latestOptimizingIdForSlot',
    params: {
      slot: slotId
    }
  })

export const resetOrderPriceInfo = () => (dispatch: ThunkDispatch) =>
  dispatch({
    type: RESET_ORDER_PRICE_INFO
  })

export const getShipmentDetailsFromTracking = (shipmentOrPackageId: string) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncQuery(SEARCH_H2_ORDER, {
      query: 'getShipmentDetailsFromTracking',
      params: {
        shipmentOrPackageId
      }
    })
  )

export const sendManualDelaySms = (orderIds: List<OrderIdType>, smsEndingType: string) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(SEND_MANUAL_DELAY_SMS, {
      type: SEND_MANUAL_DELAY_SMS.name,
      payload: {
        orderIds,
        smsEndingType
      }
    })
  )

export const sendManualDelaySmsNew = (orderIds: OrderIdType[], smsEndingType: string) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(SEND_MANUAL_DELAY_SMS_NEW, {
      type: SEND_MANUAL_DELAY_SMS_NEW.name,
      payload: {
        orderIds,
        smsEndingType
      }
    })
  )

export const sendPrePickupSmsNew = (orderIds: OrderIdType[]) => (dispatch: ThunkDispatch) =>
  dispatch(api.asyncCommand(SEND_PRE_PICKUP_SMS_NEW, { type: SEND_PRE_PICKUP_SMS_NEW.name, payload: { orderIds } }))

export function getImportResults(importId: string) {
  return (dispatch: ThunkDispatch) =>
    dispatch(
      api.asyncQuery(GET_IMPORT_RESULTS, {
        query: 'getImportResults',
        params: { importId }
      })
    )
}

export function getImportStatus() {
  return (dispatch: ThunkDispatch) =>
    dispatch(
      api.asyncQuery(GET_IMPORT_STATUS, {
        query: 'getImportStatus',
        params: {}
      })
    )
}

export const clearCommandResult = (commandResultType: string) => (dispatch: ThunkDispatch) => {
  return dispatch({
    type: CLEAR_ENTITY_COMMAND_RESULT,
    commandResultType
  })
}

export const clearEntities = (entityType: string) => (dispatch: ThunkDispatch) => {
  return dispatch({
    type: CLEAR_ENTITIES,
    entityType
  })
}

export const getConsignmentEventsWithSentLmStatus = (shipmentId: string) => (dispatch: ThunkDispatch) =>
  dispatch(
    fetchIfNotPresent(EVENTS_WITH_SENT_LM_STATUS, {
      query: 'eventsWithSentLmStatus',
      params: {
        shipmentId
      }
    })
  )

export const getOrdersByOrderIds = (orderIds: List<OrderIdType>) =>
  fetchIfNotPresent(GET_ORDERS_BY_ORDER_IDS, {
    query: 'orders',
    params: { ids: orderIds }
  })

export const updateCustomerSenderName = (payload: object, callback: () => void) => (dispatch: ThunkDispatch) => {
  dispatch(
    api.asyncCommand(UPDATE_CUSTOMER_SENDER_NAME, {
      type: 'UPDATE_CUSTOMER_SENDER_NAME',
      payload: payload
    })
  ).then(
    (res) => (res.ok ? callback() : res),
    (err) => console.log('updateCustomerSenderName', err)
  )
}

export const updateCustomerInvoiceHDOrdersFromDate =
  (payload: object, callback: () => void) => (dispatch: ThunkDispatch) => {
    dispatch(
      api.asyncCommand(UPDATE_CUSTOMER_HD_INVOICING_FROM_DATE, {
        type: 'UPDATE_CUSTOMER_HD_INVOICING_FROM_DATE',
        payload: payload
      })
    ).then(
      (res) => (res.ok ? callback() : res),
      (err) => console.log('updateCustomerInvoiceHDOrdersFromDate', err)
    )
  }

export const updateCustomerNPSFromDate = (payload: object, callback: () => void) => (dispatch: ThunkDispatch) => {
  dispatch(
    api.asyncCommand(UPDATE_CUSTOMER_NPS_FROM_DATE, {
      type: 'UPDATE_CUSTOMER_NPS_FROM_DATE',
      payload: payload
    })
  ).then(
    (res) => (res.ok ? callback() : res),
    (err) => console.log('updateCustomerNPSFromDate', err)
  )
}

export const updateCustomerBillingNumber = (payload: object, callback: () => void) => (dispatch: ThunkDispatch) => {
  dispatch(
    api.asyncCommand(UPDATE_CUSTOMER_BILLING_NUMBER, {
      type: 'UPDATE_CUSTOMER_BILLING_NUMBER',
      payload: payload
    })
  ).then(
    (res) => (res.ok ? callback() : res),
    (err) => console.log('updateCustomerBillingNumber', err)
  )
}

export const updateCustomerBookingPageAccess = (payload: object, callback: () => void) => (dispatch: ThunkDispatch) => {
  dispatch(
    api.asyncCommand(UPDATE_CUSTOMER_BOOKING_PAGE_ACCESS, {
      type: UPDATE_CUSTOMER_BOOKING_PAGE_ACCESS.name,
      payload: payload
    })
  ).then(
    (res) => (res.ok ? callback() : res),
    (err) => console.log('updateCustomerBookingPageAccess', err)
  )
}

export const updateBrandedTrackingAccess = (payload: object, callback: () => void) => (dispatch: ThunkDispatch) => {
  dispatch(
    api.asyncCommand(UPDATE_BRANDED_TRACKING_ACCESS, {
      type: UPDATE_BRANDED_TRACKING_ACCESS.name,
      payload: payload
    })
  ).then(
    (res) => (res.ok ? callback() : res),
    (err) => console.log('updateBrandedTrackingAccess', err)
  )
}

export const updateCustomerReturnLastLeg = (payload: object, callback: () => void) => (dispatch: ThunkDispatch) => {
  dispatch(
    api.asyncCommand(UPDATE_CUSTOMER_RETURN_LAST_LEG, {
      type: UPDATE_CUSTOMER_RETURN_LAST_LEG.name,
      payload: payload
    })
  ).then(
    (res) => (res.ok ? callback() : res),
    (err) => console.log('updateCustomerReturnLastLeg', err)
  )
}

export const updateCustomerReturnAddresss = (payload: object, callback: () => void) => (dispatch: ThunkDispatch) => {
  dispatch(
    api.asyncCommand(UPDATE_CUSTOMER_RETURN_ADDRESS, {
      type: UPDATE_CUSTOMER_RETURN_ADDRESS.name,
      payload: payload
    })
  ).then(
    (res) => (res.ok ? callback() : res),
    (err) => console.log('updateCustomerReturnAddresss', err)
  )
}

export const getCustomerReturnAddress = (params: object) => (dispatch: ThunkDispatch) => {
  dispatch(
    api.asyncQuery(GET_CUSTOMER_RETURN_ADDRESS, {
      query: 'customerReturnAddress',
      params: params
    })
  )
}

export const getCustomerExternalCustomerNumbers = (params: object) => (dispatch: ThunkDispatch) => {
  dispatch(
    api.asyncQuery(GET_CUSTOMER_EXTERNAL_CUSTOMER_NUMBERS, {
      query: 'customerExternalCustomerNumbers',
      params: params
    })
  )
}

export interface UpdateCustomerScanToCreatePackages {
  customerId: number
  scanToCreatePackages: boolean
}

export const updateCustomerScanToCreatePackages =
  (payload: UpdateCustomerScanToCreatePackages, callback: () => void) => (dispatch: ThunkDispatch) => {
    dispatch(
      api.asyncCommand(UPDATE_CUSTOMER_SCAN_TO_CREATE_PACKAGES, {
        type: UPDATE_CUSTOMER_SCAN_TO_CREATE_PACKAGES.name,
        payload: payload
      })
    ).then(
      (res) => (res.ok ? callback() : res),
      (err) => console.log('updateCustomerScanToCreatePackages', err)
    )
  }

export const updateOrderServiceLevel =
  (payload: object, onComplete: (success: boolean) => void, setSubmitting: (flag: boolean) => void) =>
  (dispatch: ThunkDispatch) => {
    setSubmitting(true)
    dispatch(
      api.asyncCommand(UPDATE_ORDER_SERVICE_LEVEL, {
        type: 'UPDATE_ORDER_SERVICE_LEVEL',
        payload
      })
    )
      .then(
        (res) => onComplete(res.ok),
        (err) => {
          console.error('updateOrderServiceLevel', err)
          onComplete(false)
        }
      )
      .finally(() => setSubmitting(false))
  }

export const manuallyPreAdvice = (payload: object) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(MANUAL_PRE_ADVICE, {
      type: 'MANUAL_PRE_ADVICE',
      payload
    })
  )

export const lockOrUnlockPreAdvice =
  (orderIds: OrderIdType[], locked: boolean, sendPreAdvice: boolean) => (dispatch: ThunkDispatch) =>
    dispatch(
      api.asyncCommand(LOCK_OR_UNLOCK_PRE_ADVICE, {
        type: LOCK_OR_UNLOCK_PRE_ADVICE.name,
        payload: { orderIds, locked, sendPreAdvice }
      })
    )

export const updateDeliveryTime =
  (orderId: OrderIdType, deliveryTime: DeliveryTime, onComplete: (success: boolean, err?: any) => void = () => {}) =>
  (dispatch: ThunkDispatch) =>
    dispatch(
      api.asyncCommand(UPDATE_DELIVERY_TIME, {
        type: 'UPDATE_DELIVERY_TIME',
        payload: {
          orderId,
          deliveryTime: {
            deliveryEarliest: formatAsLocalDate(deliveryTime.earliest),
            deliveryLatest: formatAsLocalDate(deliveryTime.latest)
          }
        }
      })
    ).then(
      (res) => (res.ok ? onComplete(true) : onComplete(false)),
      (err) => {
        console.log('updateDeliveryTime', err)
        onComplete(false, err)
      }
    )

export const removeShipmentBookingTimes =
  (orderId: OrderIdType, onComplete: (success: boolean, err?: any) => void = () => {}) =>
  (dispatch: ThunkDispatch) =>
    dispatch(api.asyncCommand(REMOVE_BOOKING_TIMES, { type: REMOVE_BOOKING_TIMES.name, payload: { orderId } })).then(
      (res) => (res.ok ? onComplete(true) : onComplete(false)),
      (err) => {
        console.log('removeShipmentBookingTimes', err)
        onComplete(false, err)
      }
    )

export const quickEditShipment = (payload: AnyData) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(QUICK_EDIT_SHIPMENT, {
      type: QUICK_EDIT_SHIPMENT.name,
      payload: payload
    })
  )

export const createPreAdvicePolicyRequest = (payload: AnyData) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(CREATE_PRE_ADVICE_POLICY, {
      type: 'CREATE_PRE_ADVICE_POLICY',
      payload
    })
  )

export const deletePreAdvicePolicySet = (preAdvicePolicyId: number) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(DELETE_PRE_ADVICE_POLICY, { type: 'DELETE_PRE_ADVICE_POLICY', payload: { preAdvicePolicyId } })
  )

export const getPreAdvicePolicies = () =>
  fetchIfNotPresent(GET_PRE_ADVICE_POLICIES, { query: 'preAdvicePolicies', params: {} })

export const getPreAdviceForOrder = (orderId: OrderIdType) =>
  fetchIfNotPresent(GET_PRE_ADVICE, { query: 'preAdviceForOrder', params: { orderId } })

export const getPreAdviceServicePoliciesRequest = (preAdvicePolicyId: number) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncQuery(GET_PRE_ADVICE_SERVICE_POLICIES, {
      query: 'preAdviceServicePolicies',
      params: { preAdvicePolicyId }
    })
  )

export const createUserRole = (payload: UserRolesPayload) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(CREATE_ROLE, {
      type: CREATE_ROLE.name,
      payload
    })
  )
export const updateUserRole = (payload: UserRolesPayload) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(UPDATE_ROLE, {
      type: UPDATE_ROLE.name,
      payload
    })
  )
export const deleteUserRole = (roleId: RoleId) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(DELETE_ROLE, {
      type: DELETE_ROLE.name,
      payload: { roleId }
    })
  )

export const getAllUserRoles = () => fetchIfNotPresent(ROLES, { query: 'roles', params: {} })

export const getAllWhatsThisModals = () =>
  fetchIfNotPresent(GET_ALL_WHATS_THIS_MODALS, { query: 'getAllWhatsThisModals', params: {} })

export const getWhatsThisModalById = (id: WhatsThisModalIdType) =>
  fetchIfNotPresent(GET_WHATS_THIS_MODAL_BY_ID, { query: 'getWhatsThisModalById', params: { id } })

export const getPublishedWhatsThisModals = () =>
  fetchIfNotPresent(GET_PUBLISHED_WHATS_THIS_MODALS, { query: 'getPublishedWhatsThisModals', params: {} })

export const getAllBanners = () => fetchIfNotPresent(GET_ALL_BANNERS, { query: 'getAllBanners', params: {} })
export const getPublishedBanners = () =>
  fetchIfNotPresent(GET_PUBLISHED_BANNERS, { query: 'getPublishedBanners', params: {} })

export const getBannerById = (id: BannerIdType) =>
  fetchIfNotPresent(GET_BANNER_BY_ID, { query: 'getBannerById', params: { id } })

export const getLoginPageBanner = () =>
  fetchIfNotPresent(GET_LOGIN_PAGE_BANNER, { query: 'getLoginPageBanner', params: {} })

export const validateBannerURL = (pageURL: string, id?: BannerIdType) =>
  fetchEvenIfPresent(VALIDATE_BANNER_URL, { query: 'validateBannerURL', params: { pageURL, id } })

export const createBanner = (payload: CreateBannerPayload) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(CREATE_BANNER, {
      type: CREATE_BANNER.name,
      payload: payload
    })
  )
export const updateBanner = (payload: UpdateBannerPayload) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(UPDATE_BANNER, {
      type: UPDATE_BANNER.name,
      payload: payload
    })
  )
export const deleteBanner = (id: number) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(DELETE_BANNER, {
      type: DELETE_BANNER.name,
      payload: { id }
    })
  )
export const createWhatsThisModal = (payload: CreateWhatsThisModalPayload) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(CREATE_WHATS_THIS_MODAL, {
      type: CREATE_WHATS_THIS_MODAL.name,
      payload: payload
    })
  )
export const updateWhatsThisModal = (payload: UpdateWhatsThisModalPayload) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(UPDATE_WHATS_THIS_MODAL, {
      type: UPDATE_WHATS_THIS_MODAL.name,
      payload: payload
    })
  )
export const deleteWhatsThisModal = (id: number) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(DELETE_WHATS_THIS_MODAL, {
      type: DELETE_WHATS_THIS_MODAL.name,
      payload: { id }
    })
  )

export const updateCustomer = (payload: AnyData) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(UPDATE_CUSTOMER, {
      type: UPDATE_CUSTOMER.name,
      payload: payload
    })
  )

export const createCustomer = (payload: AnyData) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(CREATE_CUSTOMER, {
      type: CREATE_CUSTOMER.name,
      payload: payload
    })
  )

export const disableJob = (jobName: string, onAccessDenied: () => void) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(DISABLE_JOB, {
      type: DISABLE_JOB.name,
      payload: {
        jobName
      }
    })
  ).catch((reason) => {
    if (reason.status === 403) {
      onAccessDenied()
    }
  })

export const enableJob = (jobName: string, onAccessDenied: () => void) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(ENABLE_JOB, {
      type: ENABLE_JOB.name,
      payload: {
        jobName
      }
    })
  ).catch((reason) => {
    if (reason.status === 403) {
      onAccessDenied()
    }
  })

export const getSuggestedUnitsForLocation =
  (departmentId: DepartmentIdType, lat: number, lng: number, dispatchDate: DateTime) => (dispatch: ThunkDispatch) =>
    dispatch(
      fetchIfNotPresent(GET_SUGGESTED_UNITS_FOR_LOCATION, {
        query: 'suggestedUnitsForLocation',
        params: { departmentId, lat, lng, dispatchDate: dispatchDate.toISODate() }
      })
    )

export const getSuggestedUnitsForOrder =
  (
    departmentId: DepartmentIdType,
    orderId: OrderIdType,
    suggestionType: UnitSuggestionType,
    additionalTimeWindowMinutes: number
  ) =>
  (dispatch: ThunkDispatch) =>
    dispatch(
      fetchIfNotPresent(suggestionType === 'pickup' ? GET_SUGGESTED_UNITS_ON_PICKUP : GET_SUGGESTED_UNITS_ON_DELIVERY, {
        query: 'suggestedUnitsForOrder',
        params: { departmentId, orderId, suggestionType, additionalTimeWindowMinutes }
      })
    )

export const createBillingOrder = (billingOrder: BillingOrder) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(CREATE_BILLING_ORDER, {
      type: 'CREATE_BILLING_ORDER',
      payload: {
        ...{
          ...billingOrder,
          items: billingOrder.items.map((item) => ({
            specification: item.specification != '' ? item.specification : undefined,
            quantity: item.quantity != '' ? item.quantity : undefined,
            customerAmount: item.customerAmount != '' ? item.customerAmount : undefined,
            carrierAmount: item.carrierAmount != '' ? item.carrierAmount : undefined,
            creditLineId: item.lineId != '' ? item.lineId : undefined,
            articleCode: item.articleCode
          }))
        }
      }
    })
  )

export const getCustomersWithTimeMatrix = () => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncQuery(GET_CUSTOMERS_WITH_TIME_MATRIX, {
      query: 'getCustomersWithTimeMatrix',
      params: {}
    })
  )

export const toggleShowCustomerPrice =
  (alystraCustomerId: string, alystraSubcustomer?: string) => (dispatch: ThunkDispatch) =>
    dispatch(
      api.asyncCommand(TOGGLE_SHOW_CUSTOMER_PRICE, {
        type: TOGGLE_SHOW_CUSTOMER_PRICE.name,
        payload: { alystraCustomerId, alystraSubcustomer }
      })
    )

export const deleteCustomerTimeMatrix =
  (alystraCustomerId: string, alystraSubcustomer?: string) => (dispatch: ThunkDispatch) =>
    dispatch(
      api.asyncCommand(DELETE_TIME_MATRIX, {
        type: DELETE_TIME_MATRIX.name,
        payload: { alystraCustomerId, alystraSubcustomer }
      })
    )

export const localizedAbbreviatedNumber = (value?: number) => (value ? abbreviateNumber(value, userLanguage) : '0')

export const createWaybillsDispatch = (orderIds: Set<number>) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(CREATE_WAYBILLS_REQUEST, {
      type: CREATE_WAYBILLS_REQUEST.name,
      payload: {
        orderIds
      }
    })
  )

export const getWaybills = (waybillsRequestId: number) =>
  fetchEvenIfPresent(GET_WAYBILLS, { query: 'waybills', params: { waybillsRequestId } })

export const getDeliveryNotes = (orderIds: OrderIdType[]) =>
  fetchEvenIfPresent(GET_DELIVERY_NOTES, { query: 'deliveryNotes', params: { orderIds } })

export const createMultilegReturn = (orderId: OrderIdType) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(CREATE_MULTILEG_RETURN, {
      type: 'CREATE_MULTILEG_RETURN',
      payload: {
        orderId: orderId
      }
    })
  )
export const createMultilegReturns = (orderIds: OrderIdType[]) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(CREATE_MULTILEG_RETURNS, {
      type: 'CREATE_MULTILEG_RETURNS',
      payload: {
        orderIds
      }
    })
  )

export const getOrderReturnDetails = (orderId: OrderIdType) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncQuery(GET_ORDER_RETURN_DETAILS, {
      query: 'getOrderReturnDetails',
      params: {
        orderId
      }
    })
  )

export const updateMultistopPriceCorrection =
  (
    groupId: string,
    correctedArticlePrices: List<CorrectedArticlePrice>,
    calculatedArticlePrices: List<IPricedArticle>
  ) =>
  (dispatch: ThunkDispatch) =>
    dispatch(
      api.asyncCommand(UPDATE_MULTISTOP_PRICE_CORRECTION, {
        type: 'UPDATE_MULTISTOP_PRICE_CORRECTION',
        payload: {
          groupId,
          correctedArticlePrices: correctedArticlePrices.map((it) => ({
            articleCode: it.get('articleCode'),
            customerPrice: it.get('customerPrice'),
            resourcePrice: it.get('resourcePrice')
          })),
          calculatedArticlePrices: calculatedArticlePrices.map((it) => ({
            articleCode: it.get('articleCode'),
            articleLabel: it.get('articleLabel'),
            customerPrice: it.get('customerPrice'),
            resourcePrice: it.get('resourcePrice')
          }))
        }
      })
    )
